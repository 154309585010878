<section class="blog blog-page">
    <app-pager [label]="'BitBoss Blog'" (goNext)="next()" (goPrev)="prev()"></app-pager>

    <ul class="blogs-list container blog-container">
        <li *ngFor="let blog of activeBlogs;" class="blog-card">
            <img src="{{ blog.img }}" alt="">
            <div class="text-block">
                <h3 class="blog-card-heading">{{ blog.title }}</h3>

                <div class="description">
                    <p>
                        <span class="text-primary sm">{{ blog.text }}</span>
                        <a class="read-more" href="{{ blog.url }}" target="_blank">
                            <button class="text-primary xsm">read more</button>
                        </a>
                    </p>
                </div>

                <div class="info-block">
                    <span class="text-primary xsm author">{{ blog.author }}</span>
                    <span class="text-primary xsm date">{{ blog.date }}</span>
                    <span class="text-primary xsm size">{{ blog.length }}</span>
                </div>
            </div>
        </li>
    </ul>
</section>

<footer class="separate">
    <app-footer></app-footer>
</footer>