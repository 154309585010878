import { Component, OnInit } from '@angular/core';
import { validate } from 'src/app/helpers/inputValidations';
import { AppConfigurationService } from 'src/app/services/app-configuration/app-configuration.service';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import {environment} from '../../../environments/environment';

declare var grecaptcha: any;

@Component({
  selector: 'app-demo',
  templateUrl: './demo.component.html',
  styleUrls: ['./demo.component.scss']
})
export class DemoComponent implements OnInit {
  private form: HTMLFormElement;
  private inputs: NodeListOf<HTMLInputElement>;
  public formValid = false;
  public captchaEnabled = false;
  public formError = '';
  public inProgress = false;

  constructor(private config: AppConfigurationService, private http: HttpClient) { }

  ngOnInit(): void {
    this.form = document.getElementById('contact-form') as HTMLFormElement;
    this.inputs = this.form.querySelectorAll('input:not([type=submit]), textarea') as NodeListOf<HTMLInputElement>;

    this.inputs.forEach(input => {
      input.addEventListener('input', () => this.onInput(input));
    });

    this.form.addEventListener('submit', e => {
      e.preventDefault();
      this.submit(e);
    });
  }

  private onInput(input): void {
    this.clearError(input);
    this.validateInputs(true);
  }

  private submit(e): void {
    if (this.inProgress) {
      return;
    }

    this.validateInputs();
    const isCaptchaValid = this.validateCaptcha();

    if (this.formValid && isCaptchaValid) {
      this.inProgress = true;
      this.sendTgMessage().subscribe(
        res => {
          this.inProgress = false;

          if (res.ok) {
            this.onSuccess();
          }
        },
        err => {
          this.inProgress = false;
          this.showGeneralError();
        }
      );
    }
  }

  private sendTgMessage(): Observable<any> {
    const result = {};

    this.inputs.forEach(i => {
      result[i.name] = i.value;
    });

    const msg = JSON.stringify(result);

    const url = `${environment.bankingUrl}/tg/message`;
    return this.http.post(url, { message: msg });
  }

  private onSuccess(): void {
    // show and hide success message
    this.form.classList.add('success');
    setTimeout(() => this.form.classList.remove('success'), 3000);

    // clear inputs
    this.inputs.forEach(i => {
      i.value = '';
    });
  }

  private showGeneralError(): void {

  }

  private clearError(el: HTMLInputElement): void {
    const parent = el.parentElement;
    parent.classList.remove('error');
  }

  private validateInputs(skipUiEffect?: boolean): void {
    console.log('validate inputs');
    this.formValid = true;

    this.inputs.forEach((input) => {
      const isValid = input.classList.contains('not-required') ? true : validate(input);
      const parent = input.parentElement;

      if (isValid) {
        parent.classList.remove('error');
      } else if (!skipUiEffect) {
        this.formValid = false;
        parent.classList.add('error');
      } else {
        this.formValid = false;
      }
    });
  }

  private validateCaptcha(): boolean {
    // Return true for now, since we don't have a backend to validate captcha response
    return true;

    if (this.captchaEnabled) {
      const captchaResponse = grecaptcha && grecaptcha.getResponse();

      if (captchaResponse && captchaResponse.length > 0) {
        const body = {
          secret: this.config.getCaptchaSecret(),
          response: captchaResponse,
        };
        // Need backend to validate captcha response
        // this.http.post('https://www.google.com/recaptcha/api/siteverify', body).subscribe(res => {
        //   console.log('===== captcha res', res);
        // });
      }
    }
  }

}
