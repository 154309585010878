<section class="hero section">
    <img class="bg" src="../../../assets/img/home/hero.jpg" alt="bitboss products">
    <h1>BitBoss Cashless Ecosystem</h1>

    <div class="container">
        <h2>
            <span class="purple">Mathematical certainty</span>
            Convenience. Lower Cost. Increased Profit.
        </h2>
        <p class="text-primary">
            <span class="purple">The BitBoss Cashless Ecosystem.</span>
            A next-generation transactional system that streamlines money management for both the player and the casino.
            Increasing revenue from EGMs and Tables, and providing players with a modernized, cashless system that
            offers
            features the next generation of younger players are particularly drawn to: instant payout, touchless,
            cashless,
            with bank access, peer to peer, and superior technology.
        </p>

        <!-- <span class="text-primary btn-video">See the video</span> -->
    </div>
</section>

<section class="blockchain" [id]="HomeAnchorsEnum.Blockchain">
    <div class="container">
        <h2>
            <span class="purple">Blockchain backbone</span>
            More permanent than stone.
            More transparent than glass.
        </h2>

        <div class="content">
            <img class="section-img" src="../../../assets/img/home/blockchain.jpg" alt="image with lights">
            <p class="text-primary purple">
                <span class="white">Powerful blockchain technology.</span>
                It's the core of BitBoss Cashless, whose elements are easy to use, despite the complex science
                facilitating
                them. Funds within the BitBoss Cashless ecosystem are tokens on a blockchain, they represent
                FDIC-insured
                bank
                deposits, giving you all the massive benefits of blockchain integration, with no fluctuation in value.
                The
                entire ecosystem runs on a public blockchain that is 100% transparent and immutable, yet maintains a
                level of
                discretion for your customers. Regulators can watch compliance in real-time on a public blockchain
                without
                needing any connections to the casino or the players directly.
            </p>
        </div>
    </div>
</section>

<section class="bridge" [id]="HomeAnchorsEnum.Wallet">
    <div class="section-content">
        <img class="section-img" src="../../../assets/img/home/mockup.jpg" alt="mobile phone">

        <h2>
            <span class="purple">BitBoss Cashless Wallet</span>
            Connected to everything.
            Always there. Branded by you.
        </h2>

        <p class="text-primary purple">
            <span class="white">One revolutionary application, </span>
            branded by your casino, handles all money management for your players. A single source provides a bridge to
            all
            the features from slot and EGM to table play, access to their bank, peer to peer transactions, and more. Our
            innovative system makes the player’s smartphone their primary transactional device, at no cost to the
            casino, and
            can leverage all of their smartphone’s biometric and other security features. This customer-facing hub of
            the
            system is pre-designed to be completely customized, enabling the casino to choose colors, logos, and even
            feature
            names to brand this app as their own. BitBoss Cashless Wallet offers players a friendly, sticky app that can
            manage
            the flow of their funds inside and outside the casino, and give them a reason to keep using it.
        </p>
    </div>
</section>

<section class="bank-integration" [id]="HomeAnchorsEnum.Bank">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/bank.jpg" alt="bank building">
        <h2>
            <span class="purple">Bank Integration</span>
            Say goodbye to the ATM.
            (and the fees).
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">A seamless connection to traditional bank accounts.</span>
                Right inside the BitBoss Cashless Wallet, players can easily deposit or withdraw money into and out of
                the
                BitBoss Cashless Ecosystem. A one-time, secure onboarding of banking credentials, by simply logging into
                their
                existing online bank account, offers players convenient access to their money. Conversion from the bank
                can
                be done from anywhere to conveniently transfer and load funds, bypassing expensive ATMs, and eliminating
                the
                need to handle cash. Your players even have the option to instantly load their BitBoss Cashless Wallet
                at
                the
                cage.
            </p>
        </div>
    </div>
</section>

<section class="peer-section" [id]="HomeAnchorsEnum.Peer">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/peer.jpg" alt="hands">
        <h2>
            <span class="purple">Peer to Peer</span>
            Send money securely.
            No middleman.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">Peer to peer transactions inside and outside of the casino. </span>
                The blockchain core of the BitBoss Cashless Wallet allows it to offer functionality that’s very similar
                to
                a
                Zelle or Venmo, but without the middle man. Players can make transfers to each other, exchanging funds
                between themselves, with no third party between them to facilitate the transaction. All transactions
                benefit
                from unbreakable blockchain security and immutable blockchain records. BitBoss Cashless is the first
                cashless,
                touchless ecosystem for a casino that offers this feature, helping you leverage the flow of money
                between
                multiple properties.
            </p>
        </div>
    </div>
</section>

<section class="slots-section" [id]="HomeAnchorsEnum.Slots">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/slots.jpg" alt="slot machine">
        <h2>
            <span class="purple">Slots and EGM</span>
            No voucher slips.
            Or visiting a cage.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">BitBoss Cashless Link. </span>
                BitBoss Cashless Link. Cashless, touchless, instant transfer of money on and off any slot or EGM.
                BitBoss
                Cashless technology supplements the current TITO system by allowing players to leverage their
                smartphones.
                A
                simple electronic handshake with the game, based on the loyalty card the player inserts in the machine,
                lets
                a player easily put funds into play and instantly collect winnings using their smartphone. The BitBoss
                Cashless
                Link hardware device sits between the motherboard and SMIB inside each EGM. It's been intelligently
                designed
                with 2 ethernet ports so you do not have to re-cable your entire floor, and, Link speaks SAS and is CMS
                agnostic. This revolutionary system makes it easier for players to move from one machine to the next,
                offering increased ROI on all your machines.
            </p>
        </div>
    </div>
</section>

<section class="table-games" [id]="HomeAnchorsEnum.Tables">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/tables.jpg" alt="dice">
        <h2>
            <span class="purple">Table Games</span>
            Deal me in <br />
            just got way easier.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">BitBoss Cashless Cashbox. </span>
                Streamlined money management for both the player and the casino using (patent pending) Cashbox and
                Chiptray
                add-ons to account for deposits from BitBoss Cashless Wallet to table games. Adding our intelligent
                transactional system provides your players an electronic handshake via a scanned QR code at the table,
                allowing them to cash in and out using their smartphone. This extends the touchless system that
                eliminates
                the need for actual cash. This superior transactional system streamlines money management for both the
                player
                and the casino, increasing revenue from tables.
            </p>
        </div>
    </div>
</section>

<section class="bridge-vault" [id]="HomeAnchorsEnum.Vault">
    <div class="container">
        <h2>
            <span class="purple">BitBoss Cashless Vault</span>
            Nothing gets in.
            There is no in.
        </h2>

        <div class="content">
            <img class="section-img" src="../../../assets/img/home/vault.jpg" alt="safe">
            <p class="text-primary purple">
                <span class="white">Distributed blockchain core.</span>
                No databases or other centralized data stores that could be altered by hackers or even bad actors within
                a
                casino IT department. BitBoss Cashless Vault is the lightweight backend server that allows a casino to
                manage
                and report on all movement of the Cashless Ecosystem funds between players, slots, game tables and works
                with
                any
                existing casino management system, no integration is needed. Our unique architecture allows Cashless
                Vault
                to
                operate securely behind your firewall only making outbound network calls, there is zero inbound network
                traffic.
            </p>
        </div>
    </div>
</section>

<section class="touchless-section" [id]="HomeAnchorsEnum.Touchless">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/touchless.jpg" alt="hand">
        <h2>
            <span class="purple">Touchless</span>
            You know where
            it's been.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">Physical cash is filthy. </span>
                It can easily assist in spreading disease. The BitBoss Cashless Ecosystem is also a cleaner and safer
                way
                to
                deal with money management for your players. The entire BitBoss Cashless Ecosystem is designed around
                touchless, cashless technology that allows players to avoid physical contact with most everything
                related to
                funds, touching virtually nothing but their own personal smartphone. With threats like COVID, players
                will be
                increasingly drawn to properties that can provide more touchless systems.
            </p>
        </div>
    </div>
</section>

<section class="security-section" [id]="HomeAnchorsEnum.Security">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/security.jpg" alt="lock">
        <h2>
            <span class="purple">Security</span>
            A mathematically secure
            system that you can trust.
            Because you don’t have to.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">Blockchain technology is trustless. </span>
                Which is to say: provably correct, no trust required. The BitBoss Cashless Ecosystem is built around
                cutting
                edge blockchain integration that by design possesses an extremely high degree of security. BitBoss
                Cashless
                works with a blockchain that has a three billion dollar market cap, protected by everyone with a vested
                interest; developers, investors, and miners. Moving funds around on the blockchain is secure by default.
                From
                a cash management standpoint, the blockchain is a permanent and immutable audit record.
            </p>
        </div>
    </div>
</section>

<section class="personal-control" [id]="HomeAnchorsEnum.Control">
    <div class="container">
        <h2>
            <span class="purple">Personal control</span>
            From players to the Casino.
            Everyone wins.
        </h2>

        <div class="content">
            <img class="section-img" src="../../../assets/img/home/control.jpg" alt="dollar icon">
            <p class="text-primary purple">
                <span class="white">A personal overview of responsible gambling.</span>
                BitBoss Cashless Wallet puts the control card in the player's hand. Patrons can easily set spending
                limits
                on
                how much they want to spend per day, per hour, or track time and money spent. This ability to manage
                activity
                and reduce the risk for problematic gambling behavior is another key feature of the BitBoss Cashless
                Ecosystem,
                directly in line with what the gambling industry, in general, is calling for.
            </p>
        </div>
    </div>
</section>

<section class="convenience-section" [id]="HomeAnchorsEnum.Convenience">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/convenience.jpg" alt="checkmark">
        <h2>
            <span class="purple">Convenience</span>
            Can it get any
            easier? Yes.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">A modern funds management system </span>
                offers the players convenience and the trust of working only with their own, familiar smartphone. Funds
                in
                the BitBoss Cashless cashless, touchless system can also be used for other amenities and merchants
                outside
                of
                the casino, or at other properties if the casino chooses to enable this functionality. As Customers KYC
                themselves into the property, this mobile wallet will carry these KYC credentials by default for all
                interactions.
            </p>
        </div>
    </div>
</section>

<section class="mobile-gaming" [id]="HomeAnchorsEnum.Mobile">
    <div class="container">
        <img class="section-img" src="../../../assets/img/home/mobile.jpg" alt="mobile phone with a card">
        <h2>
            <span class="purple">Mobile Gaming</span>
            Cash me.
            Outside.
        </h2>

        <div class="content">
            <p class="text-primary purple">
                <span class="white">Real games played for real money. </span>
                Right inside the wallet. BitBoss Cashless Wallet can also offer a library of embedded games. Real
                branded
                games
                and slots using the same odds, and played with the same funds that the player has loaded for play in the
                physical casino. A player can leave the casino floor and still actively engage with real casino gaming,
                continuing to put their funds in play and enjoying chasing that streak. Total customer engagement, even
                from
                their hotel room, the restaurant, the pool.
            </p>
        </div>
    </div>
</section>

<section class="footer-section white">
    <div class="footer-container">
        <h2>BitBoss Cashless Ecosystem</h2>
        <span class="sub-heading">Nowhere and everywhere, at the same time.</span>

        <div class="features">
            <div class="feature">
                <img src="../../../assets/img/paper-icons/bank.svg" alt="bank" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Bank on it.</span> A seamless connection to your traditional
                    bank
                    accounts.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/peer.svg" alt="hands" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Middle men are out.</span> Direct peer to peer transactions
                    inside
                    and outside of the casino.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/tables.svg" alt="dice" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Get in the game.</span> Streamlined money management, QR code
                    sign in
                    at the tables.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/slots.svg" alt="slot machine" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Vouchers no more.</span> Cashless, touchless, instant transfer
                    on and
                    off slots and EGMs.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/block.svg" alt="block" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Complex science, simple.</span> Powerful blockchain core.
                    Massive
                    benefits. Easy integration.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/vault.svg" alt="safe" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Safe as it gets. </span> Reporting is behind your firewall,
                    outbound
                    only, nothing gets in.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/touchless.svg" alt="hand" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Play it clean.</span> A cleaner and safer way to deal with
                    touchless
                    money management.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/security.svg" alt="lock" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Mathematically secure.</span> Transparent and immutable.
                    Bulletproof blockchain security.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/conven.svg" alt="checkmark" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">It’s all right there.</span> Know your customer, without the
                    slightest inconvenience.
                </p>
            </div>
            <div class="feature">
                <img src="../../../assets/img/paper-icons/control.svg" alt="dollar" class="feature-img">
                <p class="text-primary sm">
                    <span class="text-primary black sm">Responsibility included.</span> Personal tracking of activity
                    puts
                    control in the player's hand.
                </p>
            </div>
        </div>
    </div>
</section>

<footer>
    <app-footer></app-footer>
</footer>