import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ViewManagementService {
  private _isSubmenuOpen$ = new BehaviorSubject(false);
  private _isMobileMenuOpen$ = new BehaviorSubject(false);

  constructor() { }

  /* Submenu -------------------------------------------------------------------------- */
  public openSubmenu(): void {
    this._isSubmenuOpen$.next(true);
  }

  public closeSubmenu(): void {
    this._isSubmenuOpen$.next(false);
  }

  public toggleSubmenu(): void {
    this._isSubmenuOpen$.getValue()
      ? this.closeSubmenu()
      : this.openSubmenu();
  }

  public get isSubmenuOpen(): Observable<boolean> {
    return this._isSubmenuOpen$.asObservable();
  }
  /* -------------------------------------------------------------------------- */


  /* MobileMenu -------------------------------------------------------------------------- */
  public openMobileMenu(): void {
    this._isMobileMenuOpen$.next(true);
  }

  public closeMobileMenu(): void {
    this._isMobileMenuOpen$.next(false);
  }

  public toggleMobileMenu(): void {
    this._isMobileMenuOpen$.getValue()
      ? this.closeMobileMenu()
      : this.openMobileMenu();
  }

  public get isMobileMenuOpen(): Observable<boolean> {
    return this._isMobileMenuOpen$.asObservable();
  }
  /* -------------------------------------------------------------------------- */

}
