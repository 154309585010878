const regs = {
    notEmpty: /\S+/,
    email: /^([a-zA-Z0-9_.-\\+])+@([a-zA-Z0-9_.-])+\.([a-zA-Z])+([a-zA-Z])+/,
    phoneNumber: /^([\d/\- .:+]+)$/,
};

export function validate(inputEl: HTMLInputElement): boolean {
    const type = inputEl.getAttribute('type');
    const { value } = inputEl;

    let reg = regs.notEmpty;

    switch (type) {
        case 'email':
            reg = regs.email;
            break;
        case 'tel':
            reg = reg = regs.phoneNumber;
            break;
        default:
            break;
    }

    return reg.test(value);
}
