<section class="team hero">
    <div class="container team-container">
        <h1 class="black">We Make Technology of The Future</h1>
        <span class="text-primary sm subheading">
            Headquartered in Denver, Colorado, BitBoss is an international team of gaming experts, blockchain
            innovators, and technologists building technology to power the future of gaming and the new internet.
        </span>
        <ul class="features">
            <li>
                <img src="../../../assets/img/paper-icons/tables.svg" alt="dice">
                <p class="text-primary sm">
                    <span class="black">Gaming Experts.</span>
                    Gaming isn't a side note for BitBoss, we have years of experience in traditional gaming
                    environments. It is from this experience that we saw the value new distributed technology could
                    bring.
                </p>
            </li>
            <li>
                <img src="../../../assets/img/paper-icons/block.svg" alt="block">
                <p class="text-primary sm">
                    <span class="black">Blockchain Innovators.</span>
                    We don't just use blockchain, we push it forward. Our cryptography team has deep knowledge of the
                    inner working of blockchains, have contributed to public blockchain node software, and created new
                    patent pending technology.
                </p>
            </li>
            <li>
                <img src="../../../assets/img/paper-icons/bridge.svg" alt="abstraction">
                <p class="text-primary sm">
                    <span class="black">Enterprise Architects.</span>
                    Our architects bring experience from telecommunications, healthcare, and government systems to make
                    sure our systems have the scalability and security to perform in the most demanding environments.
                </p>
            </li>
            <li>
                <img src="../../../assets/img/paper-icons/block.svg" alt="block">
                <p class="text-primary sm">
                    <span class="black">System Integrators.</span>
                    Using the technology of the future doesn't mean you have to leave all your hard work behind. We work
                    hard to make sure our shiny new tech can be used to improve legacy platforms without the need to
                    completely replace them.
                </p>
            </li>
        </ul>
    </div>
</section>

<section class="team">
    <div class="container team-container">
        <div class="row">
            <ul class="team-list">
                <li class="team-card">
                    <img src="../../../assets/img/team/matt.jpg" alt="Matt Dickson">
                    <div class="text-block">
                        <h2>Matt Dickson</h2>
                        <p class="role">CEO & FOUNDER</p>
                        <p class="description">
                            Matthew started his career practicing law in New York City. He represented clients in the
                            insurance and finance sectors before forming a diversified financial service company. After
                            the sale of that company Matthew shifted his focus and formed a private equity firm
                            specializing in early stage investments. With a focus on the real money gaming sector he
                            made investments in casinos, online content providers and manufacturers.
                        </p>
                        <p class="description">
                            Missing the joy of running his own company, Matthew founded Poydras Gaming Finance
                            Corporation, which he took public on the Toronto Venture Exchange in 2014. Poydras, which
                            was recently sold to the Las Vegas based gaming company AGS, owned 2,600 slot machines. The
                            machines were leased to Indian tribes in the United States.
                        </p>
                        <p class="description">
                            During his time at Poydras he started an emerging technology lab. Once again following his
                            passion for early stage startups Matthew spun BitBoss Corporation out of Poydras and
                            assembled a highly talented team of blockchain developers to pursue opportunities in the
                            gaming sector. Since founding BitBoss he’s been issued a patent for provably fair gaming and
                            has numerous pending patent applications for blockchain gaming solutions.
                        </p>
                        <p class="description">
                            Matthew’s legal background has allowed him to understand the regulatory environment and he
                            was asked to be on the Gaming Standards Association blockchain workgroup. He has also worked
                            with testing labs to help write testing standards that allow for the inclusion of blockchain
                            technologies.
                        </p>
                    </div>
                </li>
                <li class="team-card">
                    <img src="../../../assets/img/team/alex.jpg" alt="Alex Shore">
                    <div class="text-block">
                        <h2>Alex Shore</h2>
                        <p class="role">CTO & FOUNDER</p>
                        <p class="description">
                            Alex has been developing enterprise software for 25 years and has been the founder in 3
                            software startups. He has extensive management experience in both small and large companies
                            including the use of scaled agile methodologies to empower multiple scrum teams to deliver
                            high quality software in a predictable fashion. Alex has worked across many domains and has
                            significant experience in the travel/hospitality industry as well as the gaming industry.
                        </p>
                        <p class="description">
                            Alex has a background of architecting and deploying highly scalable integration platforms
                            and working closely with vendors and partners to seamlessly connect with their systems. He
                            is currently developing blockchain applications and managing a team of cryptography and
                            blockchain experts including several offshore development teams. His technical expertise
                            includes C#, Javascript, C++, REST web services, Azure Cloud Services, Angular, Ionic, and
                            Node.js. Alex has a mobile device background that extends all the way back to creating
                            applications on the first Palm Pilot PDA devices. He holds a patent for early support of
                            timezones on mobile devices.
                        </p>
                    </div>
                </li>
                <li class="team-card">
                    <img src="../../../assets/img/team/justin.jpg" alt="Justin Laue">
                    <div class="text-block">
                        <h2>Justin Laue</h2>
                        <p class="role">CHIEF ENGINEER & FOUNDER</p>
                        <p class="description">
                            Justin has over 25 years of software development and architecture experience. He has a
                            background in low level programming for the cable and video on demand industries, where he
                            developed his Linux expertise and leveraged his deep knowledge of C++ and Java. He was the
                            engineer primarily responsible for having his company receive an Emmy award for video on
                            demand solutions. Justin has managed numerous international development teams over his
                            career.
                        </p>
                        <p class="description">
                            In recent years, Justin has become a blockchain expert, creating solutions on both private
                            and public blockchains and contributing to large, high visibility software projects
                            including Bitcoin Unlimited and Tokenized. Justin is the creator of a Bitcoin SV blockchain
                            explorer Bitfire.io. He creates APIs in Node.js that interact with public blockchains and
                            has created client side javascript libraries that interact with multichain and Bitcoin SV.
                            Justin has expertise with MongoDB, Angular, Ionic, and Reactive programming.
                        </p>
                    </div>
                </li>

            </ul>
        </div>
        <div class="row">
            <ul class="team-list">
                <li class="team-card">
                    <img src="../../../assets/img/team/Christian-Solomine.jpg" alt="Christian Solomine">
                    <div class="text-block">
                        <h2>Christian Solomine</h2>
                        <p class="role">BOARD ADVISOR</p>
                        <p class="description">
                            Christian Solomine is currently Senior Vice President of Sales for Repay Holdings, LLC
                            (NASDAQ: RPAY) and is also Board Advisor to the BitBoss Corporation. He is a dynamic and
                            highly sought-after speaker on topics such as Payments, Gaming, Internet of Things (IoT) and
                            Artificial Intelligence. Recently, he has been assisting BitBoss with their go-to-market
                            planning for their cashless solution for land-based casinos utilizing blockchain and
                            employing a FDIC-insured U.S. dollar stabletoken for seamless routing of credits on and off
                            slots and table games.
                        </p>
                        <p class="description">
                            Christian’s payments, IoT, AI and gaming experience spans the greater part of the past two
                            decades, including his time as the Chief Revenue Officer for Descartes Labs, Senior Vice
                            President of Sales & Marketing for PayNearMe and General Manager for the North America
                            Global Partner Program at Telefonica Digital.
                        </p>
                        <p class="description">
                            Christian is magna cum laude graduate of James Madison University’s College of Integrated
                            Science & Technology. He also received a Master of Science in Information Technology and
                            holds an MBA from the Daniels College at the University of Denver.
                        </p>
                    </div>
                </li>
                <li class="team-card">
                    <img src="../../../assets/img/team/roman.jpg" alt="Roman Tiutiunnyk">
                    <div class="text-block">
                        <h2>Roman Tiutiunnyk</h2>
                        <p class="role">PROJECT MANAGER</p>
                        <p class="description">
                            Roman is the project manager of the BitBoss offshore development team. He handles all
                            recruiting and day to day management for our larger projects that include Angular and React
                            Native mobile development, custom Java plugins for our Android applications, as well as the
                            creation of web services and backend solutions using Java and Node.js. Roman is heavily
                            focused on immaculate design and high quality code.
                        </p>
                    </div>
                </li>
                <li class="team-card">
                    <img src="../../../assets/img/team/cort.jpg" alt="Cortland Langworthy">
                    <div class="text-block">
                        <h2>Cortland Langworthy</h2>
                        <p class="role">CHIEF CREATIVE OFFICER & FOUNDER</p>
                        <p class="description">
                            Cort is an award-winning creative with the skills to ideate, design, and develop
                            user-focused digital projects as well as manage the teams that bring them to life. He
                            approaches all projects with a strategic UX mindset, taking into account the overall
                            business goals, brand voice and user behavior that will drive the most effective solutions.
                        </p>
                        <p class="description">
                            Before joining BitBoss, Cort has served as both a Director and VP for numerous marketing
                            agencies. He drove business development at Big Theory to the point where it was named to
                            Inc. 500 and acquired by Xceed, Inc. As the chief creative officer at BitBoss, Cort focuses
                            on user interfaces and the branding of BitBoss’ unique product set. He also manages the
                            tactical marketing teams.
                        </p>
                    </div>
                </li>
            </ul>
        </div>
        <div class="row">
            <ul class="team-list">
                <li class="team-card">
                    <img src="../../../assets/img/team/maxim.jpg" alt="Maxim Baykalov">
                    <div class="text-block">
                        <h2>Maxim Baykalov</h2>
                        <p class="role">SOFTWARE ENGINEER</p>
                        <p class="description">
                            Max specializes in mobile app development using both Ionic/Angular and React Native. He is
                            also a Java expert who codes native Android components and REST APIs.
                        </p>
                    </div>
                </li>
                <li class="team-card">
                    <img src="../../../assets/img/team/andrei.jpg" alt="ndrei Starusev">
                    <div class="text-block">
                        <h2>Andrei Starusev</h2>
                        <p class="role">FRONT-END DEVELOPER</p>
                        <p class="description">
                            Andrei specializes in mobile app development and is skilled across numerous javascript
                            frameworks including Angular and React Native.
                        </p>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</section>

<footer>
    <div class="container team-container shadow-container">
        <app-footer></app-footer>
    </div>
</footer>