import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomeComponent } from './pages/home/home.component';
import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { DemoComponent } from './pages/demo/demo.component';
import { TeamComponent } from './pages/team/team.component';
import { BlogComponent } from './pages/blog/blog.component';
import { PressComponent } from './pages/press/press.component';
import { PressPostComponent } from './pages/press-post/press-post.component';
import { PagerComponent } from './components/pager/pager.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { AcceptableUsePolicyComponent } from './pages/acceptable-use-policy/acceptable-use-policy.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    DemoComponent,
    TeamComponent,
    BlogComponent,
    PressComponent,
    PressPostComponent,
    PagerComponent,
    TermsOfServiceComponent,
    PrivacyPolicyComponent,
    AcceptableUsePolicyComponent,
    DeleteAccountComponent,
  ],
  imports: [BrowserModule, HttpClientModule, AppRoutingModule],
  providers: [],
  bootstrap: [AppComponent],
})
export class AppModule {}
