import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/services/data/data.service';

export type PressItem = {
  img: string;
  title: string;
  agenda: string;
  date: number | string;
  cardSize: 'S' | 'M' | 'L' | 'XL';
  text?: string;
  fullText?: string;
  id: number;
  externalUrl?: string;
};

@Component({
  selector: 'app-press',
  templateUrl: './press.component.html',
  styleUrls: ['./press.component.scss']
})
export class PressComponent implements OnInit {
  public press: PressItem[] = [];
  constructor(
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.fetchPress();
  }

  private fetchPress(): void {
    this.dataService.fetchPress().subscribe(res => {
      this.press = res;
    });
  }

  public getCardClassName(post: PressItem): string {
    let className = 'press-card';

    switch (post.cardSize) {
      case 'XL':
        className += ' extra-large';
        break;
      case 'L':
        className += ' large';
        break;
      case 'M':
        className += ' medium';
        break;
      case 'S':
        className += ' small';
        break;
      default:
        className += ' large';
    }

    return className;
  }
}
