<app-pager [label]="'BitBoss Press'" [isPrevDisabled]="isPrevDisabled" [isNextDisabled]="isNextDisabled" (goPrev)="prev()"
    (goNext)="next()">
</app-pager>

<section class="press-post" *ngIf="post">
    <div class="container">
        <p class="press-agenda">{{ post.agenda }}</p>
        <p class="date text-primary xsm">{{ post.date }}</p>
        <h4 class="black press-post__heading">{{ post.title }}</h4>
    </div>

    <a [href]="post.externalUrl" target="_blank">
        <img class="picture" [src]="post.img" alt="post-picture">
    </a>

    <p *ngIf="post.fullText" class="press-post__fullText container text-primary sm black">
        {{post.fullText}}
        <span *ngIf="post.externalUrl">...</span>
        <a *ngIf="post.externalUrl" [href]="post.externalUrl" class="text-primary sm purple-light read-more" target="_blank">
            …(read full article)
        </a>
    </p>
</section>

<footer>
    <app-footer></app-footer>
</footer>