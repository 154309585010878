import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map, mergeMap } from 'rxjs/operators';
import { formatTime } from 'src/app/helpers/date';
import { PressItem } from 'src/app/pages/press/press.component';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private press: PressItem[];

  constructor(private http: HttpClient) { }

  public fetchPress(): Observable<PressItem[]> {
    return this.http.get('assets/press/press.json')
      .pipe(
        mergeMap((press: PressItem[]) => {
          this.press = this.formatPress(press);
          return of(this.press);
        })
      );
  }

  public fetchPostById(articleId: number): Observable<PressItem> {
    // if press detail page was reloaded
    if (!this.press) {
      return this.fetchPress().pipe(
        mergeMap(press => of(press.find(p => p.id === articleId)))
      );
    }

    return of(this.press.find(p => p.id === articleId));
  }

  private formatPress(press: PressItem[]): PressItem[] {
    return press
      // sort by date
      .sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);
        return bDate.getTime() - aDate.getTime();
      })
      // format date
      .map(b => ({ ...b, date: formatTime(b.date) }));
  }
}
