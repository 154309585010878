<section class="team hero">
    <div class="container team-container">
        <h1 class="black">Account Deletion</h1>
        <span class="text-primary sm subheading">
            Please use one of the 2 options below to delete your account. Any cashless transaction that you have
            already performed (sending funds to the casino, receiving funds from the casino, etc.) will remain visible
            to the casino operator.
        </span>
        <ul class="features">
            <li>
                <img src="../../../assets/img/paper-icons/block.svg" alt="use the app">
                <p class="text-primary sm">
                    <span class="black">Use the App</span>
                    Select the Profile menu option and then click the Delete account link
                </p>
            </li>
            <li>
                <img src="../../../assets/img/paper-icons/block.svg" alt="email support">
                <p class="text-primary sm">
                    <span class="black">Email Support</span>
                    Request to delete your account at support@bitboss.io
                </p>
            </li>
        </ul>
    </div>
</section>

<footer>
    <div class="container team-container shadow-container">
        <app-footer></app-footer>
    </div>
</footer>