import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AppConfigurationService {
  private config: any;

  constructor(private http: HttpClient) {
    this.http.get('assets/config/app-config.json').subscribe(result => {
      this.config = result;
    });
  }

  public getCaptchaSecret(): string {
    return this.config && this.config.CAPTCHA_SECRET;
  }
}
