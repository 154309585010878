import { Component, OnInit } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { formatTime } from 'src/app/helpers/date';

type BlogItem = {
  img: string;
  title: string;
  text: string;
  author: string;
  date: number | string;
  length: string;
  url: string;
};

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.scss']
})
export class BlogComponent implements OnInit {
  private blogs: BlogItem[] = [];
  private itemsOnPage = 5;
  private pageIndex = 1;

  constructor(private http: HttpClient) { }

  ngOnInit(): void {
    this.fetchBlogs();
  }

  private fetchBlogs(): void {
    this.http.get('assets/blog/blogs.json').subscribe((blogs: BlogItem[]) => {
      console.log('blogs', blogs);
      this.blogs = blogs.map(b => ({ ...b, date: formatTime(b.date) }));
    });
  }

  public get activeBlogs(): BlogItem[] {
    // filter with page index and items on page
    return this.blogs.filter((b, i) => {
      const from = (this.pageIndex - 1) * this.itemsOnPage;
      const to = this.pageIndex * this.itemsOnPage;

      return i >= from && i < to;
    });
  }

  public next(): void {
    if (this.pageIndex > this.blogs.length / this.itemsOnPage) {
      return;
    }

    this.pageIndex++;
  }

  public prev(): void {
    if (this.pageIndex === 1) {
      return;
    }

    this.pageIndex--;
  }
}
