import { Component, OnDestroy, OnInit } from '@angular/core';
import { combineLatest, Subject, } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { HomeAnchorsEnum } from 'src/app/pages/home/home.component';
import { ViewManagementService } from 'src/app/services/view-management/view-management.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy {
  public subMenuItems: {
    iconSrc: string;
    label: string;
    routerLink: HomeAnchorsEnum;
  }[] = [
      { iconSrc: '../../../assets/img/submenu/blockchain.svg', label: 'Blockchain Backbone', routerLink: HomeAnchorsEnum.Blockchain },
      { iconSrc: '../../../assets/img/submenu/wallet.svg', label: 'BitBoss Bridge Wallet', routerLink: HomeAnchorsEnum.Wallet },
      { iconSrc: '../../../assets/img/submenu/bank.svg', label: 'Bank Integration', routerLink: HomeAnchorsEnum.Bank },
      { iconSrc: '../../../assets/img/submenu/peer.svg', label: 'Peer to Peer', routerLink: HomeAnchorsEnum.Peer },
      { iconSrc: '../../../assets/img/submenu/slots.svg', label: 'BitBoss Bridge Link | Slots', routerLink: HomeAnchorsEnum.Slots },
      { iconSrc: '../../../assets/img/submenu/tables.svg', label: 'BitBoss Bridge Cashbox | Tables', routerLink: HomeAnchorsEnum.Tables },
      { iconSrc: '../../../assets/img/submenu/vault.svg', label: 'BitBoss Bridge Vault', routerLink: HomeAnchorsEnum.Vault },
      { iconSrc: '../../../assets/img/submenu/touchless.svg', label: 'Touchless', routerLink: HomeAnchorsEnum.Touchless },
      { iconSrc: '../../../assets/img/submenu/security.svg', label: 'Security', routerLink: HomeAnchorsEnum.Security },
      { iconSrc: '../../../assets/img/submenu/control.svg', label: 'Personal Controls', routerLink: HomeAnchorsEnum.Control },
      { iconSrc: '../../../assets/img/submenu/conve.svg', label: 'Convenience', routerLink: HomeAnchorsEnum.Convenience },
      { iconSrc: '../../../assets/img/submenu/mobile.svg', label: 'Mobile Gaming', routerLink: HomeAnchorsEnum.Mobile },
    ];
  public isSubmenuOpen = false;
  public isMobileMenuOpen = false;

  private destroy$ = new Subject();

  constructor(
    private viewManagementService: ViewManagementService
  ) { }

  ngOnInit(): void {
    combineLatest([
      this.viewManagementService.isSubmenuOpen,
      this.viewManagementService.isMobileMenuOpen
    ])
      .pipe(takeUntil(this.destroy$))
      .subscribe(([isSubmenuOpen, isMobileMenuOpen]) => {
        this.isSubmenuOpen = isSubmenuOpen;
        this.isMobileMenuOpen = isMobileMenuOpen;
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public toggleSubmenu(): void {
    this.viewManagementService.toggleSubmenu();
  }

  public toggleMobileMenu(): void {
    this.viewManagementService.toggleMobileMenu();
  }

}
