<section class="demo footer-section white">
    <div class="footer-container">
        <div class="heading-block">
            <img class="logo" src="../../../assets/img/logo-paper.svg" alt="bitboss logo">
            <div>
                <h2>Let’s talk business</h2>
                <span class="sub-heading">A Demo of the future is just a few clicks away.</span>
            </div>
        </div>

        <div class="form-block">
            <p class="text-primary sm">
                <span class="text-primary black sm">BitBoss Bridge Ecosystem</span> Nowhere and everywhere at the same
                time. The cashless, touchless, modernized system your players want. Increased convenience and profit,
                through superior technology.
            </p>

            <form action="" id="contact-form">
                <label class="text-primary black sm">
                    Name <input class="text-primary black xsm" name="name" type="text">
                    <span class="text-primary sm error-text">This field is required</span>
                </label>
                <label class="text-primary black sm">
                    Company <input class="text-primary black xsm" name="company" type="text">
                    <span class="text-primary sm error-text">This field is required</span>
                </label>
                <label class="text-primary black sm">
                    Email <input class="text-primary black xsm" name="email" type="email">
                    <span class="text-primary sm error-text">Invalid email</span>
                </label>
                <label class="text-primary black sm">
                    Phone <input class="text-primary black xsm" name="phone" type="tel">
                    <span class="text-primary sm error-text">Invalid phone number</span>
                </label>
                <label class="text-primary black sm">
                    Comments <textarea class="text-primary black xsm not-required" name="comments" maxlength="250"
                        rows="4"></textarea>
                    <!-- <span class="text-primary sm error-text">The message length should not exceed 250 characters</span> -->
                </label>
                <div class="buttons">
                    <span class="text-primary green sm success-msg">Thanks for reaching out. We'll be in touch
                        soon.</span>
                    <div *ngIf="captchaEnabled" class="captcha g-recaptcha"
                        data-sitekey="6LfIr8IZAAAAAEC1UrXtzdylIjObFMsdnaqHJEHc"></div>
                    <input class="submit text-primary white sm" type="submit" value="Submit"
                        [disabled]="!formValid ||inProgress" />
                </div>
            </form>
        </div>

    </div>
</section>

<footer>
    <app-footer></app-footer>
</footer>