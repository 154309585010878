import { Component } from '@angular/core';

export enum HomeAnchorsEnum {
  Blockchain = 'blockchain',
  Wallet = 'wallet',
  Bank = 'bank',
  Peer = 'peer',
  Tables = 'tables',
  Slots = 'slots',
  Vault = 'vault',
  Touchless = 'touchless',
  Security = 'security',
  Convenience = 'convenience',
  Control = 'control',
  Mobile = 'mobile',
}
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent {
  public HomeAnchorsEnum = HomeAnchorsEnum; // To provide enum to the template

  constructor() { }

}
