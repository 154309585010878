<header class="main-nav">
    <div class="container">
        <img class="logo" src="../../../assets/img/logo.svg" alt="bitboss logo" (click)="toggleSubmenu()">

        <div class="links" [ngClass]="{active: isMobileMenuOpen}">
            <nav>
                <ul class="menu">
                    <li><a class="nav-link" routerLink="" (click)="toggleMobileMenu()">Cashless</a></li>
                    <li><a class="nav-link" routerLink="/team" (click)="toggleMobileMenu()">About</a></li>
                </ul>
            </nav>

            <a routerLink="/demo">
                <button class="btn-primary purple main-nav__demo-btn" (click)="toggleMobileMenu()">
                    Request a Demo
                </button>
            </a>
        </div>

        <div class="burger-btn" [ngClass]="{active: isMobileMenuOpen}" (click)="toggleMobileMenu()"> </div>
    </div>
</header>

<header class="submenu" [ngClass]="{submenu_active: isSubmenuOpen}">
    <ul class="submenu__items-container">
        <li *ngFor="let subItem of subMenuItems">
            <a class="submenu__item" [routerLink]="'home'" [fragment]="subItem.routerLink">
                <div class="submenu__icon-wrapper">
                    <img [src]="subItem.iconSrc" alt="">
                </div>

                <span class="submenu__item-label text-primary black xsm">
                    {{subItem.label}}
                </span>
            </a>
        </li>
    </ul>
</header>
<div class="submenu__overlay" [ngClass]="{submenu__overlay_active: isSubmenuOpen}" (click)="toggleSubmenu()"></div>