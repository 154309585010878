import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    this.adjustTeamRows();
    window.addEventListener('resize', () => this.onResize());
  }

  private onResize(): void {
    // remove perv value
    const rows = document.querySelectorAll('.team-list') as NodeListOf<HTMLElement>;
    rows.forEach(r => { r.style.height = 'unset'; });

    this.adjustTeamRows();
  }

  // set rows height of maximum card height
  // to allow all the rest cards stretch to this height
  private adjustTeamRows(): void {
    if (window.innerWidth <= 500) {
      return;
    }

    const rows = document.querySelectorAll('.team-list') as NodeListOf<HTMLElement>;

    rows.forEach(row => {
      const cards = row.querySelectorAll('.team-card');
      let maxHeight = 0;

      cards.forEach((card, i) => {
        const height = card.getBoundingClientRect().height;

        if (i === 0) {
          console.log('height', height);
        }

        if (height > maxHeight) {
          maxHeight = height;
        }
      });

      row.style.height = `${maxHeight}px`;
    });
  }

}
