import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from 'rxjs';
import { flatMap, take } from 'rxjs/operators';
import { PressItem } from 'src/app/pages/press/press.component';
import { DataService } from 'src/app/services/data/data.service';

@Component({
  selector: 'app-press-post',
  templateUrl: './press-post.component.html',
  styleUrls: ['./press-post.component.scss']
})
export class PressPostComponent implements OnInit {
  public post: PressItem;
  public isPrevDisabled: boolean;
  public isNextDisabled: boolean;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private dataService: DataService
  ) { }

  ngOnInit(): void {
    this.route.params
      .pipe(
        flatMap(
          ({ id }) => forkJoin([
            this.dataService.fetchPostById(Number.parseInt(id, 10)),
            this.dataService.fetchPress()
          ])
        )
      )
      .subscribe(([post, press]: [PressItem, PressItem[]]) => {
        this.post = post;
        this.isPrevDisabled = post.id === 1;
        this.isNextDisabled = post.id === press.length;
      });
  }

  public prev(): void {
    this.route.params
      .pipe(take(1))
      .subscribe(({ id }) => {
        this.router.navigate(['press/post/' + (id - 1)]);
      });
  }

  public next(): void {
    this.route.params
      .pipe(take(1))
      .subscribe(({ id }) => {
        this.router.navigate(['press/post/' + (+id + 1)]);
      });
  }

}
