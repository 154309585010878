import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from 'src/app/pages/home/home.component';
import { DemoComponent } from 'src/app/pages/demo/demo.component';
import { TeamComponent } from 'src/app/pages/team/team.component';
import { BlogComponent } from 'src/app/pages/blog/blog.component';
import { PressComponent } from 'src/app/pages/press/press.component';
import { PressPostComponent } from './pages/press-post/press-post.component';
import { PrivacyPolicyComponent } from './pages/privacy-policy/privacy-policy.component';
import { TermsOfServiceComponent } from './pages/terms-of-service/terms-of-service.component';
import { AcceptableUsePolicyComponent } from './pages/acceptable-use-policy/acceptable-use-policy.component';
import { DeleteAccountComponent } from './pages/delete-account/delete-account.component';

const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  { path: 'home', component: HomeComponent },
  { path: 'demo', component: DemoComponent },
  { path: 'team', component: TeamComponent },
  { path: 'blog', component: BlogComponent },
  { path: 'press', component: PressComponent },
  { path: 'press/post/:id', component: PressPostComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-of-service', component: TermsOfServiceComponent },
  { path: 'acceptable-use-policy', component: AcceptableUsePolicyComponent },
  { path: 'delete-account', component: DeleteAccountComponent },
  { path: '**', redirectTo: 'home' },
];

@NgModule({
  // imports: [RouterModule.forRoot(routes)],
  imports: [
    RouterModule.forRoot(routes, {
      useHash: true,
      enableTracing: true,
      onSameUrlNavigation: 'reload',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
