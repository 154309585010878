<div class="c30">
        <p class="c23"><span class="c31">BitBoss Privacy Policy</span></p>
        <p class="c1"><span class="c22">&nbsp;</span></p>
        <p class="c8"><span class="c22 c34">Last revised on November 22, 2022</span></p>
        <p class="c1"><span class="c0">Overview</span></p>
        <p class="c1"><span class="c5 c3">This privacy statement explains how and why BitBoss collects, stores, uses,
                        and
                        shares personal data when you visit our websites or use our services. Reading it will help you
                        understand
                        your privacy rights and choices.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">&ldquo;Personal data&rdquo; in this statement means information about you,
                        including your identity, finances, and online behavior.</span></p>
        <p class="c1"><span class="c5 c3">&ldquo;BitBoss&rdquo; in this statement means BitBoss, Inc. This privacy
                        statement
                        only applies to the BitBoss services offered by BitBoss.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Your privacy rights and choices</span></p>
        <p class="c1"><span class="c5 c3">When it comes to how your personal data is collected, stored, used, and
                        shared,
                        you have rights and choices.</span></p>
        <p class="c1"><span class="c14">&nbsp;</span></p>
        <p class="c1"><span class="c0">Understanding your rights</span></p>
        <p class="c1"><span class="c5 c3">You have the right to request a copy of the personal data we&rsquo;ve
                        collected
                        about you in the past 12 months. You also have the right to ask us to delete your personal data
                        we have
                        collected about you. If you want to see or delete your personal data, you can email us at
                        support@bitboss.io.
                </span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">If you request to see or delete your personal data, we&rsquo;ll first need to
                        verify who you are before we can respond to your request. If we can&rsquo;t verify your
                        identity, we will
                        not be able to fulfill your request.</span></p>
        <p class="c1"><span class="c5 c3">If you want to delete your personal data, you have choices:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Contact us and request that we delete specific information</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Close your account</span></p>
        <p class="c1"><span class="c5 c3">If you close your account or request that we delete personal data, we still
                        need
                        to keep some personal data so we can:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Complete a transaction or comply with our promises to you in the Terms of
                        Service</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Detect and prevent malicious, fraudulent, or illegal activity</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Protect your (or another person&rsquo;s) legal rights, including the right to free
                        speech</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Manage our internal business processes that are reasonably related to your
                        expectations when
                        using our services</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c3 c5">Comply with laws</span></p>
        <p class="c1"><span class="c5 c3">Depending on where you live and what type of account you use, you may have
                        different rights and choices for managing your personal data. For example, certain State privacy
                        laws do not
                        apply to personal data collected, processed, or disclosed by a financial institution according
                        to federal
                        laws, such as the Gramm-Leach-Bliley Act.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Understanding your choices</span></p>
        <p class="c1"><span class="c5 c3">You can control how personal data is collected or shared, as well as how we
                        communicate with you.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Choose how we communicate with you</span></p>
        <p class="c1"><span class="c5 c3">Your choices about how we communicate with you differ depending on the purpose
                        of
                        the message and how it is delivered. Some messages are considered optional and some are
                        necessary for you to
                        manage your accounts with us. We use email, text messages, or push notifications on your mobile
                        device.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">You can click the unsubscribe link in a marketing email, opt out of a text
                        message
                        by replying &ldquo;STOP,&rdquo; or turn off notifications on your device. You can also change
                        your
                        account&rsquo;s notification settings or the notification preferences on your device.</span></p>
        <p class="c1"><span class="c5 c3">You won&rsquo;t be able to opt out of messages that are considered necessary
                        for
                        you to manage your account, such as receipts and emails that alert you to changes in your
                        account&rsquo;s
                        status that require your attention. You may be able to decide how we send those messages, such
                        as by email,
                        text message, or a notification on your mobile device.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">The personal data we collect</span></p>
        <p class="c1"><span class="c5 c3">We may collect your personal data when you register for or use our services,
                        such
                        as when you create an account or make a transfer. </span></p>
        <p class="c1"><span class="c5 c3">If you use our services without creating or logging into an account,
                        we&rsquo;ll
                        still collect personal data, which may include your payment information, device information, and
                        location.
                        When you use our services without creating or logging into an account, we will use this
                        information to
                        process transactions, prevent fraud and comply with the law. We may connect this information
                        with your
                        account, if you have one or if you create an account at a later date.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">Here are the kinds of personal data that we may collect when you create an
                        account
                        or use our services:</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">1. Information that identifies you, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">First and last name</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Address</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Phone number</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Email</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">IP address</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Information collected from cookies or other tracking technologies</span></p>
        <p class="c1"><span class="c5 c3">2. Records and financial information, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Social Security Number</span></p>
        <p class="c4 c7"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Government-issued identification</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Bank account and routing numbers</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Credit and debit card information</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Financial information</span></p>
        <p class="c1"><span class="c5 c3">3. Personal characteristics, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Age</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">National origin</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Disability</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Citizenship</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Military status</span></p>
        <p class="c1"><span class="c5 c3">4. Commercial information, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Transfer history</span></p>
        <p class="c1"><span class="c5 c3">5. Internet or network activity, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Interactions with our services or sites</span></p>
        <p class="c1"><span class="c5 c3">6. Geolocation data, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Global Positioning System (GPS) information when you give us permission through
                        your device
                        settings</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">IP-based geolocation</span></p>
        <p class="c1"><span class="c5 c3">7. Audio, electronic, visual, biometric, or similar information, for
                        example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Call recordings when you talk to customer service</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Voice identification, when you consent to this biometric method of
                        authentication</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Photo IDs and profile pictures you may provide</span></p>
        <p class="c1"><span class="c5 c3">8. Professional or employment information, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Business information, contact emails and phone numbers</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Tax IDs</span></p>
        <p class="c1"><span class="c5 c3">9. Information we infer based on your personal data, for example:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Fraud and risk assessments</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Personalization preferences</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Where personal data comes from</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;We may collect personal information about you from various sources, for
                        example from:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">You</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Program Participants</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Our payment partners, such as card networks and payment processors</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Financial institutions, such as banks</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Credit bureaus</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Service providers, such as companies that help us manage risk and fraud, deliver
                        services to
                        you, and market our services</span></p>
        <p class="c1"><span class="c5 c3">We do not knowingly collect personal information from people who are not
                        allowed
                        to use our sites and services, such as children under the age of 13. Email us at
                        support@bitboss.io
                        &nbsp;if
                        you believe that we&rsquo;ve mistakenly collected personal data from someone not allowed to use
                        our
                        services. We&rsquo;ll delete it immediately, unless we&rsquo;re legally required to keep
                        it.</span></p>
        <p class="c1"><span class="c14">&nbsp;</span></p>
        <p class="c1"><span class="c0">How we use tracking technologies like cookies</span></p>
        <p class="c1"><span class="c5 c3">We or our authorized service providers may use cookies and similar tracking
                        technologies to collect personal data whenever you use our services, visit our sites, or visit
                        websites that
                        offer our services. The information collected with these technologies helps us personalize your
                        experience,
                        measure the effect of our ads, prevent fraud and enhance the security of our sites and
                        service.</span></p>
        <p class="c1"><span class="c5 c3">You can disable or decline some cookies for our sites and services. But, since
                        some parts of our service rely on cookies to work, those services could become difficult or
                        impossible to
                        use.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Why we collect personal data</span></p>
        <p class="c1"><span class="c5 c3">We collect personal data for many reasons, including to improve your
                        experience,
                        and to run our business. Specific reasons why we collect your personal data include:</span></p>
        <p class="c1 c4"><span class="c5 c3">1. &nbsp; &nbsp; &nbsp;Run our sites and provide better services, for
                        example
                        to help you send a transfer, show you your account information, verify access to your account,
                        and keep your
                        account and payment information up to date.</span></p>
        <p class="c1 c4"><span class="c5 c3">2. &nbsp; &nbsp; &nbsp;Manage and improve our business. For example, we do
                        user
                        research to improve our products&rsquo; performance and abilities. We also monitor and analyze
                        our sites to
                        help ensure they work as expected. </span></p>
        <p class="c1 c4"><span class="c5 c3">3. &nbsp; &nbsp; &nbsp;Protect our business and our customers from risk and
                        fraud, including fraud that involves our business partners, strategic ventures, or other
                        individuals and
                        Program Participants.</span></p>
        <p class="c1 c4"><span class="c5 c3">4. &nbsp; &nbsp; &nbsp;Send you marketing information about our products
                        and
                        services. We may use personal data to market about our partners. We use personal data to better
                        understand
                        and cater to your interests.</span></p>
        <p class="c1 c4"><span class="c5 c3">5. &nbsp; &nbsp; &nbsp;Personalize your experience when you use our sites
                        and
                        services, as well as other third-party sites and services. In this case, we might use tracking
                        technologies
                        like cookies.</span></p>
        <p class="c1 c4"><span class="c5 c3">6. &nbsp; &nbsp; &nbsp;Send you locally relevant options, but only if you
                        agree
                        to let us track your location. If you do, this can help us enhance the security of our sites and
                        services,
                        and customize our services by using the right language and personalizing content such as ads and
                        search
                        results.</span></p>
        <p class="c1 c4"><span class="c5 c3">7. &nbsp; &nbsp; &nbsp;Make it easy for you to find and connect with
                        others.
                        For instance, if you let us access your contacts, we can suggest connections with people you may
                        know.</span></p>
        <p class="c1 c4"><span class="c5 c3">8. &nbsp; &nbsp; &nbsp;Contact you when you need us, such as answering a
                        question you sent to our customer service team.</span></p>
        <p class="c1 c4"><span class="c5 c3">9. &nbsp; &nbsp; &nbsp;Comply with laws and enforce our agreements with you
                        and
                        other people who use our services.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">How and why we share personal data</span></p>
        <p class="c1"><span class="c5 c3">We do not sell your personal data. However, we may share personal data across
                        our
                        services and with other members of the BitBoss corporate family. Sometimes we share the personal
                        data we
                        collect with third parties to help us provide services, protect our customers from risk and
                        fraud, market
                        our products, and comply with legal obligations.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">We may share personal data with:</span></p>
        <p class="c1 c4"><span class="c5 c3">1. &nbsp; &nbsp; &nbsp;Other members of the BitBoss corporate family</span>
        </p>
        <p class="c1 c4"><span class="c5 c3">2. &nbsp; &nbsp; &nbsp;Service providers that help us with processing
                        payments,
                        marketing, research, compliance, audits, corporate governance, communications, and
                        security</span></p>
        <p class="c1 c4"><span class="c5 c3">3. &nbsp; &nbsp; &nbsp;Card networks and payment processors</span></p>
        <p class="c1 c4"><span class="c5 c3">4. &nbsp; &nbsp; &nbsp;Credit reporting and collections agencies</span></p>
        <p class="c1 c4"><span class="c5 c3">5. &nbsp; &nbsp; &nbsp;Courts, governments, regulators and law enforcement
                        when
                        accompanied by a subpoena or other legal documentation that requires BitBoss or members of our
                        corporate
                        family to respond</span></p>
        <p class="c1 c4"><span class="c5 c3">6. &nbsp; &nbsp; &nbsp;People involved in a transaction, such as other
                        users or
                        Program Participants and their service providers.</span></p>
        <p class="c1 c4"><span class="c5 c3">7. &nbsp; &nbsp; &nbsp;Third parties that you asked us to connect with,
                        such as
                        other financial or social media apps. If you want us to stop sharing information with a third
                        party,
                        disconnect your account from that third party.</span></p>
        <p class="c1 c4"><span class="c5 c3">8. &nbsp; &nbsp; &nbsp;Third-party tools used to help fight spam and
                        abuse.</span></p>
        <p class="c1 c4"><span class="c5 c3">9. &nbsp; &nbsp; &nbsp;Other third parties to:</span></p>
        <p class="c7 c11"><span class="c6 c3">o &nbsp; </span><span class="c5 c3">Comply with laws</span></p>
        <p class="c7 c11"><span class="c6 c3">o &nbsp; </span><span class="c5 c3">Investigate or enforce violations of
                        our
                        user agreement</span></p>
        <p class="c7 c11"><span class="c6 c3">o &nbsp; </span><span class="c5 c3">Facilitate a merger, purchase, or sale
                        of
                        part or all of our business</span></p>
        <p class="c7 c11"><span class="c6 c3">o &nbsp; </span><span class="c5 c3">Comply with card association
                        rules</span>
        </p>
        <p class="c7 c11"><span class="c3 c6">o &nbsp; </span><span class="c5 c3">To prevent physical harm or illegal
                        activity</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">How we protect your personal data</span></p>
        <p class="c1"><span class="c5 c3">Helping to keep your personal data safe against loss, misuse, unauthorized
                        access,
                        disclosure, and alteration is our top priority.</span></p>
        <p class="c1"><span class="c5 c3">To protect your personal data, we use technical, physical, and administrative
                        security measures that include:</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Firewalls</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Data encryption</span></p>
        <p class="c7 c4"><span class="c3">&middot; &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span
                        class="c5 c3">Physical access controls at our data centers</span></p>
        <p class="c1"><span class="c5 c3">While we protect our systems and services, you&rsquo;re responsible for
                        keeping
                        your password(s) and account information private. You are also responsible for making sure your
                        personal
                        information is accurate and up to date.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>


        <p class="c1"><span class="c0">Our data retention policy</span></p>
        <p class="c1"><span class="c5 c3">We retain personal data for the least amount of time
                        necessary to fulfill our legal or regulatory obligations and for our business purposes. We may
                        retain personal data for longer periods than required by law if it is in our legitimate business
                        interests and not prohibited by law. If your account is closed, we may take steps to mask
                        personal data and other information, but we reserve our ability to retain and access the data
                        for so long as required to comply with applicable laws. We will continue to use and disclose
                        such personal data in accordance with this Privacy Policy.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">How this statement changes over time</span></p>
        <p class="c1"><span class="c5 c3">We&rsquo;ll make changes to this privacy statement from time to time. This
                        helps
                        us stay up to date with changes to our business and the most current laws. After a new version
                        is published,
                        we&rsquo;ll collect, store, use, and protect your personal data as we outline in that revised
                        statement.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">If the new version reduces your rights or increases your responsibilities,
                        we&rsquo;ll post it on the Terms of Service page of our website at least 30 days before it
                        becomes
                        effective.</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1"><span class="c5 c3">We may notify you about these changes through email or other
                        communications.</span></p>
        <p class="c1"><span class="c5 c20">&nbsp;</span></p>
        <p class="c1"><span class="c0">Contact Us</span></p>
        <p class="c1"><span class="c5 c3">If you have questions about this privacy statement or your personal data,
                        contact
                        us so we can help. &nbsp;To talk about your BitBoss account:</span></p>
        <p class="c1"><span class="c5 c3">&nbsp;</span></p>
        <p class="c1 c33"><span class="c3">&middot; &nbsp; </span><span class="c5 c3">Email BitBoss Customer Service at
                        support@bitboss.io </span></p>
        <p class="c32"><span class="c18">&nbsp;</span></p>
        <p class="c21"><span class="c20 c37">Electronic Fund Transfers (&ldquo;EFTs&rdquo;) and Account
                        Balances.</span><span class="c5 c20">&nbsp;</span></p>
        <p class="c21"><span class="c22 c3">You authorize BitBoss to share your identity and banking information with
                        our financial partners.</span></p>
        <p class="c15 c21"><span class="c5 c20"></span></p>
        <p class="c28"><span class="c25">California Privacy Rights. </span><span class="c2">If you are a resident of the
                        State of California, under the California Consumer Privacy Act (CCPA), you have the right to
                        request
                        information on how to exercise your disclosure choice options from companies conducting business
                        in
                        California. &nbsp;Specifically:</span></p>
        <ul class="c19 lst-kix_bekbokbadp5h-0 start">
                <li class="c21 c29 li-bullet-0"><span class="c25">Exercising the Right to Know.</span><span
                                class="c10 c20">&nbsp;</span><span class="c2">You may request, up to twice in a 12-month
                                period, the
                                following information about the personal information we have collected about you during
                                the past 12
                                months:</span></li>
        </ul>
        <ul class="c19 lst-kix_bekbokbadp5h-1 start">
                <li class="c9 li-bullet-0"><span class="c2">the categories and specific pieces of personal information
                                we have
                                collected about you;</span></li>
                <li class="c9 li-bullet-0"><span class="c2">the categories of sources from which we collected the
                                personal
                                information;</span></li>
                <li class="c9 li-bullet-0"><span class="c2">the business or commercial purpose for which we collected
                                the
                                personal information;</span></li>
                <li class="c9 li-bullet-0"><span class="c2">the categories of third parties with whom we shared the
                                personal
                                information; and</span></li>
                <li class="c28 c36 li-bullet-0"><span class="c2">the categories of personal information about you that
                                we
                                disclosed for a business purpose, and the categories of third parties to whom we
                                disclosed that
                                information for a business purpose.</span></li>
        </ul>
        <ul class="c19 lst-kix_59pprcyg7d0d-0 start">
                <li class="c21 c29 li-bullet-0"><span class="c25">Exercising the right to delete</span><span
                                class="c10 c27">.</span><span class="c10 c20">&nbsp;</span><span class="c10 c3">You may
                                request that we
                                delete the personal information we have collected from you, subject to certain
                                limitations under
                                applicable law.</span></li>
                <li class="c21 c29 li-bullet-0"><span class="c25">Exercising the right to opt-out from a
                                sale</span><span class="c10 c27">.</span><span class="c10 c20">&nbsp;</span><span
                                class="c10 c3">You may request to opt
                                out of any &ldquo;sale&rdquo; of your personal information that may take place.</span>
                </li>
                <li class="c26 li-bullet-0"><span class="c25">Non-discrimination</span><span class="c10 c27">.
                        </span><span class="c3 c10">The CCPA provides that you may not be discriminated against for
                                exercising these
                                rights.</span></li>
        </ul>
        <p class="c16"><span class="c2">To submit a request to exercise any of the rights described above, you may
                        contact
                        BitBoss either via email to support@bitboss.io or contact us via postal mail, proper postage
                        prepaid,
                        at:</span></p>
        <p class="c16"><span class="c2">BitBoss, Attn: Your California Privacy Rights</span></p>
        <p class="c1 c13"><span class="c5 c3">7435 South Eastern Ave</span></p>
        <p class="c1 c13"><span class="c5 c3">Suite 105-431</span></p>
        <p class="c1 c13"><span class="c5 c3">Las Vegas, NV 89123</span></p>
        <p class="c1 c13 c15"><span class="c12 c3"></span></p>
        <p class="c16"><span class="c2">Please indicate your preference as to how you would like us to respond to your
                        request (i.e., email or postal mail).</span></p>
        <p class="c16"><span class="c10 c3">All requests sent via postal mail must be labeled &ldquo;Your California
                        Privacy
                        Rights&rdquo; on the envelope or postcard and clearly stated on the actual request. For all
                        requests, please
                        include your name, street address (if you would like a response via postal mail), city, state,
                        and zip code.
                        We may need to verify your identity before responding to your request, such as verifying that
                        the email
                        address or contact information from which you send the request matches your email address or
                        contact
                        information that we have on file. Authentication based on a government-issued and valid
                        identification
                        document may be required. We will not accept requests via telephone or fax. We are not
                        responsible for
                        notices that are not labeled or sent properly, or do not have complete information.</span></p>
</div>

<footer>
        <div class="container team-container shadow-container">
                <app-footer></app-footer>
        </div>
</footer>