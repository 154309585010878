<div [className]="isDemoPage ? 'demo footer-container' : 'footer-container'">
    <div class="copy-block">
        <p class="text-primary xsm">
            To see BitBoss Cashless close up and personal,
            <a routerLink="/demo" class="text-primary xsm purple-light">request a Demo</a>
            or email <a href="mailto:support@bitboss.io">support@bitboss.io</a>
        </p>
        <div class="content">
            <p class="text-primary xsm">Copyright © {{currentYear}} BitBoss Inc. All rights reserved</p>
            <a class="text-primary xsm" routerLink="/privacy-policy">Privacy Policy</a>
            <!-- <a class="text-primary xsm" routerLink="/terms-of-service">Terms of Service</a> -->
            <a class="text-primary xsm" routerLink="/acceptable-use-policy">Acceptable Use Policy</a>
        </div>
    </div>

    <div class="address-block">
        <div class="text-primary xsm">
            <div class="text-primary black xsm">BitBoss Corporation</div>
            <span class="phone-number"><a href="tel:17204329145">+1 720.432.9145</a></span>
            <div><a href="mailto:support@bitboss.io">support@bitboss.io</a></div>
        </div>
    </div>

    <div class="social">
        <a target="_blank" href="https://www.linkedin.com/company/bitboss-corporation/"><img
                src="../../../assets/img/icon-linkedin.svg" alt="linkedin icon"></a>
        <!-- <a target="_blank" href="/"><img src="../../../assets/img/icon-telegram.svg" alt="telegram icon"></a> -->
        <a target="_blank" href="https://twitter.com/bitbossio"><img src="../../../assets/img/icon-twitter.svg"
                alt="twitter icon"></a>
    </div>
</div>