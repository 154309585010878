const monthNames = ['January', 'February', 'March', 'April', 'May', 'June',
  'July', 'August', 'September', 'October', 'November', 'December'
];

export function getMonthName(index: number): string {
    return monthNames[index];
}

export function formatTime(date: number | string): string {
  const d = new Date(date);

  return `${getMonthName(d.getMonth())} ${d.getDate()}, ${d.getFullYear()}`;
}
