import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-pager',
  templateUrl: './pager.component.html',
  styleUrls: ['./pager.component.scss']
})
export class PagerComponent {
  @Input() label: string;
  @Input() isPrevDisabled: boolean;
  @Input() isNextDisabled: boolean;

  @Output() goPrev = new EventEmitter();
  @Output() goNext = new EventEmitter();

  constructor() { }

  public prev(): void {
    this.goPrev.emit();
  }

  public next(): void {
    this.goNext.emit();
  }
}
