import { Component, ElementRef, ViewChild } from '@angular/core';
import { Router, Scroll } from '@angular/router';
import { filter, pluck } from 'rxjs/operators';
import { ViewManagementService } from './services/view-management/view-management.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'bitboss-bridge-io';

  @ViewChild('content_container') content: ElementRef;

  constructor(
    private router: Router,
    private viewManagementService: ViewManagementService,
  ) {

    this.router.events
      .pipe(
        filter((event) => event instanceof Scroll),
        pluck('anchor')
      )
      .subscribe((anchor: string) => {
        this.viewManagementService.closeSubmenu();
        setTimeout(() =>
          anchor
            ? this.scrollToAnchor(anchor)
            : this.scrollContentToTop()
        );
      });
  }

  public scrollToAnchor(anchor: string): void {
    document
      .querySelector('#' + anchor)
      .scrollIntoView({ block: 'start', behavior: 'smooth' });
  }

  public scrollContentToTop(): void {
    this.content.nativeElement.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }
}
