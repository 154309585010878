<div class="c33 c42">
        <p class="c38"><span class="c14 c20 c39">BitBoss Terms of Service</span></p>
        <p class="c9 c21"><span class="c14 c13 c1"></span></p>
        <p class="c9"><span class="c4 c13">Last revised on November 22, 2022</span></p>
        <p class="c8"><span class="c6">1. Introduction</span></p>
        <p class="c9"><span class="c0">The BitBoss program (the &ldquo;</span><span class="c5">Program</span><span
                        class="c0">&rdquo;) is operated and maintained by BitBoss, Inc. (&ldquo;</span><span
                        class="c5">BitBoss</span><span class="c0">&rdquo; &ldquo;</span><span class="c5">we</span><span
                        class="c0">&rdquo; &ldquo;</span><span class="c5">our</span><span class="c0">&rdquo; or
                        &ldquo;</span><span class="c5">us</span><span class="c0">&rdquo;) a </span><span
                        class="c0">Texas, </span><span class="c0">USA
                        corporation. &nbsp;</span><span class="c0">We operate the Program to enable you to transfer the
                        BitBoss
                        Digital US Dollar to any participating individual, business, governmental or nongovernmental
                        organization
                        (&ldquo;Program Participant&rdquo;) in the United States that accepts the BitBoss Terms of
                        Service and
                        passes the BitBoss applicable compliance approval process. &nbsp;</span><span
                        class="c0 c4">Program services
                        may be made using our software applications on your computer or mobile device. We sometimes call
                        &ldquo;</span><span class="c5 c4">you</span><span class="c0 c4">&rdquo; a &ldquo;</span><span
                        class="c5 c4">user</span><span class="c14 c0 c4">&rdquo; of the Program.</span>
                <span class="c0"> The term <span class="c5 c4">Account</span> refers to a demand deposit account opened
                        at
                        Evolve Bank & Trust
                        ("Bank") governed by the Bank's Customer Account Agreement.
                        These Terms are not part of the Customer Account Agreement that governs your demand deposit
                        account you have with Evolve.
                        Evolve is not a party to, nor has any liability to you under these Terms,
                        and the services provided hereunder by us are independent of the services provided by Evolve.
                </span>
        </p>
        <p class="c9"><span class="c0 c4">By using or accessing the Program, our related &ldquo;</span><span
                        class="c5 c4">Website</span><span class="c0 c4">&rdquo; &ndash; www.bitboss.io or our software
                        applications
                        on your computer or mobile device (each and all called the &ldquo;</span><span
                        class="c5 c4">Service</span><span class="c0 c4">&rdquo;), you agree to be bound by these Terms
                        of Service
                        (&ldquo;</span><span class="c5 c4">Terms</span><span class="c14 c0 c4">&rdquo;). If you
                        don&rsquo;t agree to
                        all of these Terms, then refrain from using the Service.</span></p>
        <p class="c9"><span class="c14 c0 c4">We reserve the right to change or modify these Terms at any time and in
                        our
                        sole discretion. If we make changes to these Terms, we will provide notice of such changes by
                        sending an
                        email or alert posting a notice on the Service or updating the &ldquo;Last Revised&rdquo; date
                        above. Any
                        changes or additions will be effective thirty (30) days after such notice. Your continued use of
                        the Service
                        will confirm your acceptance to the revised Terms. &nbsp;Therefore, you should frequently review
                        these Terms
                        and all related information that may apply to your use of the Service. If you do not agree to
                        the updated
                        Terms, you must stop using the Service.</span></p>
        <p class="c9"><span class="c0 c4">Some Services may have additional or different terms and conditions that
                        supplement these terms. These terms are intended to apply generally across many places and
                        customs to ensure
                        fairness, uniformity and better service levels for all users of the Service, however, to
                        accommodate the
                        special regulatory requirements of certain kinds of Operators,&nbsp;</span><span
                        class="c0 c35 c4">e.g.</span><span class="c0 c4">, online or land-based gaming platforms, or
                        certain state
                        requirements, we may agree to modify these Terms for the benefit of such Operators, authorities
                        and users by
                        separate agreement. To the extent a particular provision of these Terms is illegal in a specific
                        place or
                        for a certain kind of user or </span><span class="c0">transfer</span><span
                        class="c14 c0 c4">&nbsp;recipient, then it will not be enforceable to that extent, and these
                        Terms will be
                        reasonably and equitably adjusted at our discretion with this general intent in mind.</span></p>
        <p class="c8"><span class="c6">2. Eligibility and Registration</span></p>
        <p class="c9"><span class="c14 c0 c4">By using the Service, you represent and warrant that you (a) have not been
                        previously suspended or removed from the Service and (b) have full power and authority to agree
                        to these
                        Terms.</span>
                <span class="c0">
                        Eligibility and registration require an Account to be
                        opened and compliance with all terms and conditions of the
                        Bank's Customer Account Agreement.
                </span>
        </p>
        <p class="c9">
                <span class="c0 c4">
                        You may only register one Account to use with the Service. You will
                        also promptly notify us if you discover or otherwise suspect
                        any security breaches use of your Account with the Service. Your
                        Account information will be stored on our remote cloud service.
                </span>
        </p>
        <p class="c9"><span class="c0 c4">
                        All information and instructions received from your Account
                        will be deemed to have been authorized by you and the
                        recipients of this information can reasonably rely on its
                        authenticity. You will be responsible for the actions taken by
                        anyone accessing the Service using your password and/or
                        Account designation or credentials.
                </span></p>
        <p class="c9"><span class="c0 c4">By using the Service, you confirm that you have reviewed these terms and
                        accepted
                        them with ample opportunity to get advice from others and to choose from other </span><span
                        class="c0">transfer</span><span class="c0 c4">&nbsp;alternatives. In any event, BitBoss may
                        reasonably rely
                        on these understandings and BitBoss reserves the right to terminate your access to all or any
                        portion of the
                        Service at any time.</span></p>
        <p class="c11 c21"><span class="c14 c26 c17"></span></p>
        <p class="c8"><span class="c16">3. </span><span class="c16">Privacy Policy and User Data</span></p>
        <p class="c9"><span class="c0 c4">Please refer to our </span><span class="c0 c18 c27">

                        <a class="c3" routerLink="/privacy-policy">Privacy Policy</a></span><span
                        class="c0 c17">&nbsp;</span><span class="c14 c0 c4">for
                        information on how we
                        collect, use, and disclose information about our users.</span></p>
        <p class="c8"><span class="c6">4. User Payment Terms</span></p>
        <p class="c9"><span class="c0 c4">The Program allows </span><span class="c0">you to transfer the BitBoss Digital
                        US
                        Dollar to any Program Participant in the United States that accepts the BitBoss Terms of Service
                        and passes
                        the BitBoss applicable compliance approval process</span><span class="c0">. &nbsp;</span></p>
        <p class="c9"><span class="c0 c4">Neither BitBoss, nor its agents are responsible for fulfilling your order,
                        adjusting your account balance with the </span><span class="c0">Program Participant</span><span
                        class="c0 c4">, ensuring the quality of the merchandise or service or its delivery. </span><span
                        class="c0">Program Participant</span><span class="c0 c4">s are responsible for adjusting their
                        own books and
                        records with respect to users,&nbsp;</span><span class="c0 c35 c4">e.g.</span><span
                        class="c0 c4">,
                        outstanding account or loan balances. Generally, all </span><span
                        class="c0">transfers</span><span class="c0 c4">&nbsp;to </span><span class="c0">approved Program
                        Participant</span><span class="c0 c4">s are
                        final and nonrefundable, unless BitBoss does not send your </span><span
                        class="c0">transfer</span><span class="c0 c4">&nbsp;or does not give instructions committing an
                        equivalent amount of money to the
                </span><span class="c0">Program Participant</span><span class="c0 c4">&nbsp;designated by you within ten
                        days of
                        the date of </span><span class="c0">the transfer </span><span class="c0 c4">and you do not
                        receive the
                        benefit of the </span><span class="c0">transfer</span><span class="c0 c4">&nbsp;that you made.
                        Any refund
                        request after BitBoss has sent your </span><span class="c0">transfer</span><span
                        class="c0 c4">&nbsp;must be
                        directed to the </span><span class="c0">Program Participant</span><span
                        class="c0 c4">.&nbsp;</span><span class="c0 c35 c4">&nbsp;</span><span class="c0 c4">See
                        your&nbsp;Applicable State Refund Policy&nbsp;and
                        refer to your receipt for any state-specific details.&nbsp;&nbsp;&nbsp;If you involve us in a
                        dispute with
                        your </span><span class="c0">Program Participant</span><span class="c0 c4">, bank, or credit
                        card company
                        that is not the result of our failure to fulfill our obligations, you will be obligated to
                        reimburse our
                        costs, including attorneys&rsquo; fees, unless the </span><span class="c0">Program
                        Participant</span><span class="c14 c0 c4">&nbsp;accepts such obligation and is further subject
                        to
                        Section 11 below.</span></p>
        <p class="c9"><span class="c0 c4">Pursuant to these Terms, BitBoss will at its complete discretion: (1) Create
                        an
                        information record for you; (2) Process </span><span class="c0">transfers</span><span
                        class="c0 c4">&nbsp;on
                        a timely and accurate basis by applying </span><span class="c0">Program Participant</span><span
                        class="c0 c4">&nbsp;instructions, Bank and Program policies, and BitBoss&rsquo; proprietary
                        methods; and (3)
                        Provide standard support information to address inquiries regarding use of the Program. BitBoss
                        reserves the
                        right to decline any </span><span class="c0">transfer</span><span class="c0 c4">&nbsp;request.
                        BitBoss also
                        reserves the right to change </span><span class="c0">funding</span><span
                        class="c0 c4">&nbsp;methods and
                </span><span class="c0">Program Participant</span><span class="c0 c4">s without notice and cannot
                        guarant</span><span class="c0">ee</span><span class="c0 c4">&nbsp;the availability of a specific
                </span><span class="c0">funding</span><span class="c0 c4">&nbsp;method or </span><span
                        class="c0">Program
                        Participant</span><span class="c14 c0 c4">&nbsp;at any time. There may be times when the Service
                        is not
                        available to you. For example, system maintenance, back-ups or failures may limit the
                        Service&rsquo;s
                        availability. Access to the Service is provided on an &ldquo;as available&rdquo; basis and you
                        are not
                        guaranteed access at any particular time.</span></p>
        <p class="c9"><span class="c0 c4">In consideration for your use of the Service, BitBoss may collect a
                        transaction
                        fee at the time of </span><span class="c0">transfer</span><span class="c0 c4">&nbsp;or
                        disbursement.&nbsp;
                        Some </span><span class="c0">Program Participant</span><span class="c0 c4">s pay this fee. When
                        they do not,
                        you pay the applicable fee as a convenience fee charge in addition to the </span><span
                        class="c0">transfer</span><span class="c0 c4">&nbsp;you are making to the </span><span
                        class="c0">Program
                        Participant</span><span class="c14 c0 c4">. BitBoss may elect to waive its fees from time to
                        time for
                        charitable or promotional purposes.</span></p>
        <p class="c9"><span class="c0 c4">You must provide complete and accurate information while using the Service and
                        must update all Account information and timely respond to requests for additional information
                        truthfully.
                        You understand that to engage in some transactions, or use a particular </span><span
                        class="c0">funding</span><span class="c0 c4">&nbsp;method, you may be required to provide
                        additional
                        identifying information. You expressly authorize BitBoss and its affiliates, customers, vendors,
                        Suppliers,
                        licensors</span><span class="c0">&nbsp;and </span><span class="c0 c4">agents to exercise all
                        good faith
                        measures to verify the information provided both initially and from time to time thereafter. You
                        will not
                        use any information provided through the Program for any purpose other than for making lawful
                </span><span class="c0">transfers</span><span class="c0 c4">&nbsp;and completing transactions
                </span><span class="c0 c4">with bona fide </span><span class="c0">Program Participant</span><span
                        class="c0 c4">s</span><span class="c0 c4">. All other uses are </span><span
                        class="c0 c4">prohibited</span><span class="c14 c0 c4">. You will always act reasonably and in
                        good faith in
                        connection with your use of the Program, recognizing that the Program&rsquo;s purpose is to
                        facilitate
                        remote commerce and enhance the integrity and efficiency of such transactions. If BitBoss
                        concludes at its
                        complete discretion that any use by you frustrates this purpose, BitBoss may prohibit your use
                        of the
                        Program.</span></p>
        <p class="c9"><span class="c0 c4">The Program may not be used to make </span><span
                        class="c0">transfers</span><span class="c0 c4">&nbsp;by persons under 21 years old, or other
                        legally prescribed age required in </span><span class="c0">Program Participant</span><span
                        class="c0 c4">&rsquo;s line of business, for: any form of illegal
                        gambling activities (including underage gambling); firearms, controlled substances, prescription
                        drugs,
                        steroids, or related paraphernalia; obscenity, copyrighted materials where the merchant does not
                        have the
                        right to copy or distribute such materials.
                        You acknowledge your obligation to know the <span class="c0">Program Participant</span>
                        that you are paying along with related legal
                        limitations (including age requirements) and to avoid misuse.
                </span>
        </p>
        <p class="c8"><span class="c6">5. User Conduct</span></p>
        <p class="c9"><span class="c14 c0 c4">In using the Service, you agree:</span></p>
        <ul class="c32 lst-kix_list_5-0 start">
                <li class="c30 li-bullet-0"><span class="c14 c0 c4">not to disrupt or interfere with the security of, or
                                otherwise abuse, the Service, or any services, system resources, accounts, servers or
                                networks connected
                                to or accessible through the Service or affiliated or linked web sites;</span></li>
                <li class="c2 li-bullet-0"><span class="c14 c0 c4">not to disrupt or interfere with any other
                                user&rsquo;s
                                enjoyment of the Service or affiliated or linked websites;</span></li>
                <li class="c2 li-bullet-0"><span class="c14 c0 c4">not to upload, post or otherwise transmit through or
                                on the
                                Service any viruses or other harmful, disruptive or destructive files;</span></li>
                <li class="c2 li-bullet-0"><span class="c0 c4 c14">not to use or attempt to use or access another
                                person&rsquo;s
                                account or personal information, or create or use a false identity on the
                                Service;</span></li>
                <li class="c2 li-bullet-0"><span class="c14 c0 c4">not to attempt to obtain unauthorized access to the
                                Service
                                or portions of the Service which are restricted from general access; and</span></li>
                <li class="c34 c33 li-bullet-0"><span class="c14 c0 c4">not to use any robot, spider or other automatic
                                device,
                                or manual process to monitor, extract, collect, harvest or copy the web pages or any
                                data or data fields
                                contained at the Service including, but not limited to, personally identifiable
                                information of any other
                                user of the Service.</span></li>
        </ul>
        <p class="c9"><span class="c0 c4">In addition, you agree that you are solely responsible for actions and
                        communications undertaken or transmitted through the
                        Service using your Account, and that you will comply with all
                        applicable local, state and federal laws and regulations that relate
                        to your use of or activities on the Service.
                </span></p>
        <p class="c8"><span class="c6">6. Jurisdiction</span></p>
        <p class="c9"><span class="c14 c0 c4">The Service is solely directed to individuals residing in the United
                        States.
                        We make no representation that any portion of the Service is appropriate or available for use in
                        locations
                        outside the United States.</span></p>
        <p class="c8"><span class="c6">7. Ownership</span></p>
        <p class="c9"><span class="c0 c4">BitBoss and/or its Suppliers, and licensors either own or license all right,
                        title, and interest in the Service and may change them at any time. Nothing in these Terms
                        confers any
                        license to our intellectual property rights, whether by estoppel, implication or otherwise. You
                        may report
                        bugs and fixes, provide feature requests, comments, testimonials, feedback and suggestions to
                        BitBoss by
                        emailing us </span><span class="c0 c4">at</span><span class="c0">&nbsp;</span><span
                        class="c0 c18">support@BitBoss.io</span><span class="c0">&nbsp;</span><span
                        class="c0 c4">(&ldquo;</span><span class="c5 c4">Feedback</span><span class="c0 c4">&rdquo;).
                        BitBoss may use the Feedback without any
                        compensation, notice or attribution to you and will own all intellectual property rights in such
                        Feedback.</span></p>
        <p class="c8"><span class="c6">8. Trademarks</span></p>
        <p class="c9"><span class="c0 c4">The trademarks, logos and service marks used and displayed herein and in
                        connection with the Program and in the Service (collectively, the &ldquo;</span><span
                        class="c4 c5">Trademarks</span><span class="c14 c0 c4">&rdquo;) are the trademarks of BitBoss,
                        Inc., and our
                        affiliates.&nbsp; The Trademarks may not be copied, imitated or used, in whole or in part,
                        without the prior
                        written permission of BitBoss or the applicable trademark holder. Any authorized use of the
                        Trademarks must
                        be in accordance with guidelines that we may provide you from time to time.&nbsp; The term
                        Trademarks as
                        used in these Terms does not include the trademarks, intellectual property, or other Service or
                        material
                        owned by third parties and not covered under a license with BitBoss. Reference to any products,
                        services,
                        trademarks, or service marks in the Program or in the Service is not an endorsement or
                        recommendation by
                        BitBoss of these products or services.</span></p>
        <p class="c8"><span class="c6">9. Warranty and Disclaimers</span></p>
        <p class="c9"><span class="c0 c4">WE WILL MAKE REASONABLE, GOOD FAITH EFFORTS TO PROVIDE THE SERVICES LAWFULLY
                        IN
                        THE MANNER THAT WE DESCRIBE IN THESE TERMS, WHILE MINDFUL OF YOUR REASONABLE NEEDS AND
                        EXPECTATIONS AND
                        APPLICABLE INDUSTRY STANDARDS OF WHICH WE ARE AWARE. THE SERVICE IS OTHERWISE PROVIDED &ldquo;AS
                        IS&rdquo;
                        &ldquo;WHEN AVAILABLE&rdquo;. BITBOSS AND ALL THIRD PARTIES BITBOSS HAS CONTRACTED WITH TO
                        PROVIDE THE
                        SERVICES, INCLUDING ITS AFFILIATES, AGENTS, AND THIRD PARTY PAYMENT PROCESSORS (collectively the
                        &ldquo;</span><span class="c4 c19">SUPPLIERS</span><span class="c14 c0 c4">&rdquo;) DISCLAIM ALL
                        WARRANTIES,
                        GUARANTIES AND CONDITIONS OF ANY KIND, WHETHER EXPRESS, IMPLIED, OR STATUTORY, INCLUDING ALL
                        IMPLIED
                        WARRANTIES, GUARANTIES AND CONDITIONS OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, AND
                        NON-INFRINGEMENT. BITBOSS AND ITS SUPPLIERS DO NOT WARRANT THAT THE SERVICE IS ACCURATE, ITS
                        FUNCTIONS WILL
                        MEET YOUR REQUIREMENTS, OR THAT USE WILL BE UNINTERRUPTED, ERROR FREE, OR FREE FROM VIRUSES OR
                        OTHER
                        COMPONENTS THAT MIGHT CAUSE DAMAGE TO YOUR PROPERTY, OR THAT ANY DEFECTS OR ERRORS WILL BE
                        CORRECTED. YOUR
                        SOLE REMEDY FOR ANY SURVIVING WARRANTIES WILL BE TO CEASE USE OF THE SERVICE OR TO TIMELY ADVISE
                        BITBOSS OF
                        ANY DEFICIENCY AND ALLOW BITBOSS TO CORRECT IT TO THE EXTENT PRACTICAL AS REASONABLY DETERMINED
                        BY BITBOSS.
                        THE DURATION OF ANY SURVIVING WARRANTY, GUARANTY OR CONDITION THAT CANNOT BE DISCLAIMED WILL NOT
                        EXCEED
                        NINETY DAYS OR ANY LONGER SPECIFICALLY MANDATED PERIOD.</span></p>
        <p class="c8"><span class="c6">10. Limitation of Liability</span></p>
        <p class="c9"><span class="c0 c4">BITBOSS AND ITS SUPPLIERS WILL NOT BE LIABLE TO ANYONE FOR ANY DAMAGES
                        INCLUDING
                        BUT NOT LIMITED TO, DIRECT, INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL, OR PUNITIVE DAMAGES,
                        ARISING OUT
                        OF, RELATING TO, OR RESULTING FROM YOUR USE, INABILITY TO USE, OR OPERATION OF THE SERVICE,
                        INCLUDING
                        WITHOUT LIMITATION ANY DAMAGES CAUSED BY OR RESULTING FROM RELIANCE BY YOU ON ANY INFORMATION
                        OBTAINED FROM
                        US OR THAT RESULT FROM MISTAKES, OMISSIONS, INTERRUPTIONS, DELETION OF FILES, ERRORS, DEFECTS,
                        VIRUSES,
                        DELAYS IN OPERATION OR TRANSMISSION OR ANY FAILURE OF PERFORMANCE, WHETHER OR NOT RESULTING FROM
                        ACTS OF
                        GOD, COMMUNICATIONS FAILURE, THEFT, DESTRUCTION OR UNAUTHORIZED ACCESS TO OUR SERVICES. BITBOSS
                        AND ITS
                        SUPPLIERS WILL NOT BE LIABLE BASED UPON ANY THEORY OF LIABILITY, INCLUDING, FOR EXAMPLE, BREACH
                        OF CONTRACT
                        OR WARRANTY, NEGLIGENCE, OR STRICT LIABILITY, EVEN IF ADVISED OF THE POSSIBILITY OF SUCH
                        DAMAGES. THE
                        DISCLAIMERS OF WARRANTIES AND LIMITATIONS OF LIABILITY WILL APPLY TO THE MAXIMUM EXTENT
                        PERMITTED BY
                        APPLICABLE LAW, EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL PURPOSE. IN NO EVENT WILL BITBOSS AND
                        ITS
                        SUPPLIERS BE LIABLE TO USER IF LOSSES RESULT FROM: USE OF THE SERVICE IN A MANNER THAT VIOLATES
                        APPLICABLE
                        LAW OR BITBOSS&rsquo;S TERMS OF SERVICE; LOSSES OR DELAYS IN TRANSMISSION OF INSTRUCTIONS
                        ARISING OUT OF THE
                        USE OF ANY INTERNET ACCESS SERVICE OR TELECOMMUNICATIONS PROVIDER OR CAUSED BY ANY BROWSER OR
                        OTHER
                        SOFTWARE; PROVISION OF LATE, INCORRECT OR INCOMPLETE INFORMATION; MALFUNCTION OR INOPERABILITY
                        OF ANY
                        EQUIPMENT OR SYSTEMS; INSUFFICIENT CREDITWORTHINESS OR IDENTIFICATION OR LACK OF GOOD STANDING
                        BASED ON
                        BITBOSS&rsquo;S PROPRIETARY METHODOLOGIES OR OTHER STANDARD PRACTICES; CIRCUMSTANCES BEYOND
                        BITBOSS&rsquo;S
                        REASONABLE CONTROL, SUCH AS, BUT NOT LIMITED TO, POWER OUTAGES, FIRE, FLOOD, CHANGE OF LAW OR
                        ENFORCEMENT
                        PRACTICES, MECHANICAL, TELECOMMUNICATIONS OR SYSTEMS FAILURES; INTERCEPTION OF </span><span
                        class="c0">TRANSFERS</span><span class="c0 c4">&nbsp;BY LEGAL PROCESS OR OTHER ENCUMBRANCES
                        RESTRICTING
                        EXECUTION OF THE </span><span class="c0">TRANSER</span><span class="c0 c4">; INABILITY TO
                        CONFIRM
                        USER&rsquo;S IDENTITY; OR, SUSPECTED FRAUD. BITBOSS, YOUR </span><span class="c0">PROGRAM
                        PARTICIPANT</span><span class="c14 c0 c4">S AND RESPECTIVE PAYMENT PROCESSORS ARE NOT
                        RESPONSIBLE FOR ANY
                        OVERDRAFT OR INSUFFICIENT FUND CHARGES THAT MAY BE INCURRED BY YOU FOLLOWING YOUR INSTRUCTIONS
                        OR THE
                        IMPROPER USE OF THE SERVICES BY THIRD PARTIES IN A MANNER INJURIOUS TO YOU. YOUR ONLY RECOURSE
                        TO BITBOSS IF
                        THE PROGRAM IS NOT AVAILABLE OR CANNOT COMPLETE A TRANSACTION WHEN PRESENTED IS TO TRY AGAIN
                        LATER OR TO
                        CONTACT CONSUMER SUPPORT.</span></p>
        <p class="c9"><span class="c0 c4">SOME STATES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF DAMAGES SO SOME OF THE
                        ABOVE LIMITATIONS MAY NOT APPLY TO YOU. IN SUCH STATES, BITBOSS AND ITS SUPPLIERS&rsquo; TOTAL
                        LIABILITY TO
                        YOU IN CONNECTION WITH EACH TRANSACTION WILL NEVER EXCEED ENSURING THAT YOUR </span><span
                        class="c0">TRANSFER </span><span class="c0 c4">WILL BE PAID TO THE DESIGNATED VENDOR OR
                </span><span class="c0">PROGRAM PARTICIPANT</span><span class="c0 c4">&nbsp;WITHIN A REASONABLE PERIOD
                        OF TIME LESS OUR
                        FEES, I.E., THE AMOUNT OF THE </span><span class="c0">TRANSFER</span><span
                        class="c14 c0 c4">.</span></p>
        <p class="c8"><span class="c14 c4 c25">11. Dispute Resolution</span></p>
        <p class="c9"><span class="c5 c4">Binding Arbitration.</span><span class="c0 c4">&nbsp;Except for disputes
                        in
                        which either party or BitBoss or its Suppliers seek to bring an individual action in small
                        claims court or
                        seek injunctive or other equitable relief for the alleged unlawful use of copyrights,
                        trademarks, trade
                        names, logos, trade secrets or patents, you, BitBoss and its Suppliers agree: (a) to waive your,
                        BitBoss&rsquo; and its Suppliers&rsquo; right to have any and all disputes arising from or
                        connected to
                        these Terms and/or the Service (collectively, &ldquo;</span><span
                        class="c5 c4">Disputes</span><span class="c14 c0 c4">&rdquo;) resolved in a court; and (b) to
                        waive your, BitBoss&rsquo;, and its
                        Suppliers&rsquo; right to a jury trial.&nbsp; Instead, you, BitBoss, and any applicable Supplier
                        agree to
                        arbitrate Disputes through binding arbitration (which is the referral of a Dispute to one or
                        more persons
                        charged with reviewing the Dispute and making a final and binding determination to resolve it
                        instead of
                        having the Dispute decided by a judge or jury in court).</span></p>
        <p class="c9"><span class="c5 c4">No Class Arbitrations, Class Actions or Representative Actions.
                </span><span class="c14 c0 c4">&nbsp; YOU, BITBOSS, AND ITS SUPPLIERS AGREE THAT
                        DISPUTES ARE PERSONAL TO YOU, BITBOSS,
                        AND ITS SUPPLIERS AND THAT DISPUTES WILL BE RESOLVED SOLELY THROUGH INDIVIDUAL ARBITRATION AND
                        WILL NOT BE
                        BROUGHT AS A CLASS ARBITRATION, CLASS ACTION OR ANY OTHER TYPE OF REPRESENTATIVE
                        PROCEEDING.&nbsp; YOU,
                        BITBOSS, AND ITS SUPPLIERS AGREE THAT A DISPUTE CANNOT BE BROUGHT AS A CLASS OR OTHER TYPE OF
                        REPRESENTATIVE
                        ACTION, WHETHER WITHIN OR OUTSIDE OF ARBITRATION, OR ON BEHALF OF ANY OTHER INDIVIDUAL OR GROUP
                        OF
                        INDIVIDUALS.</span></p>
        <p class="c9"><span class="c5 c4">Federal Arbitration Act.</span><span class="c0 c4">&nbsp; You, BitBoss,
                        and
                        its Suppliers agree that these Terms affect interstate commerce and that the enforceability of
                        this Section
                        11 shall be governed by, construed and enforced, both substantively and procedurally, by the
                        Federal
                        Arbitration Act, 9 U.S.C. &sect; 1 et seq. (the &ldquo;</span><span
                        class="c5 c4">FAA</span><span class="c14 c0 c4">&rdquo;) to the maximum extent permitted by
                        applicable law.</span></p>
        <p class="c9"><span class="c5 c4">Process.</span><span class="c0 c4">&nbsp; You, BitBoss, and its
                        Suppliers
                        agree that we will notify each other in writing of any Dispute within thirty (30) days of when
                        it arises so
                        that we can attempt in good faith to resolve the Dispute informally.&nbsp; Notice to BitBoss
                        shall be sent
                        by certified mail or courier to: BitBoss Corporation, Attn: Legal, </span><span class="c0">7435
                        South
                        Eastern Ave,
                        Suite 105-431, Las Vegas, NV 89123</span><span class="c0 c1">.&nbsp;&nbsp;</span><span
                        class="c0 c4">Your
                        notice must include: (a) your name and contact information; (b) a description of the nature or
                        basis of the
                        Dispute; and (c) the specific relief that you are seeking.&nbsp; If you, BitBoss, and any
                        applicable
                        Supplier cannot agree to resolve the Dispute within thirty (30) days of BitBoss or its Supplier
                        receiving
                        your notice, then either you, we, or our Suppliers may, as appropriate pursuant to this Section
                        11, commence
                        an arbitration proceeding or file a claim in court.&nbsp; You, BitBoss, and its Suppliers agree
                        that any
                        arbitration or claim must be commenced or filed within one (1) year after the Dispute arise;
                        otherwise, you,
                        BitBoss, and its Suppliers agree that the claim is permanently barred (which means that you will
                        no longer
                        have the right to assert a claim regarding the Dispute). You, BitBoss, and its Suppliers agree
                        that (1) any
                        arbitration will occur in</span><span class="c0 c1">&nbsp;</span><span class="c0">7435 South
                        Eastern Ave,
                        Suite 105-431, Las Vegas, NV 89123</span><span class="c0 c17">&nbsp;</span><span
                        class="c0 c4">which you may
                        attend either in person or via videoconference or telephone; (2) arbitration will be conducted
                        confidentially by a single arbitrator in accordance with the rules of JAMS; and (3) the state or
                        federal
                        courts in</span><span class="c0 c1">&nbsp;</span><span class="c0">7435 South Eastern Ave, Suite
                        105-431, Las
                        Vegas, NV 89123</span><span class="c0 c17">&nbsp;</span><span class="c14 c0 c4">will have
                        exclusive
                        jurisdiction over any appeals and the enforcement of an arbitration award and over any Dispute
                        between the
                        parties that is not subject to arbitration; provided, however, that small claims courts in the
                        jurisdiction
                        in which you reside will have jurisdiction over small claims disputes.</span></p>
        <p class="c9"><span class="c5 c4">Authority of Arbitrator.</span><span class="c14 c0 c4">&nbsp; As limited
                        by
                        the FAA, these Terms and the applicable JAMS rules, the arbitrator will have (a) the exclusive
                        authority and
                        jurisdiction to make all procedural and substantive decisions regarding a Dispute; (b) the
                        authority to
                        grant any remedy that would otherwise be available in court; and (c) the authority to determine
                        gateway
                        issues of arbitration.&nbsp; The arbitrator may only conduct an individual arbitration and may
                        not
                        consolidate more than one individual&rsquo;s claims, preside over any type of class or
                        representative
                        proceeding or preside over any proceeding involving more than one individual.</span></p>
        <p class="c9"><span class="c5 c4">Rules of JAMS.</span><span class="c0 c4">&nbsp; The rules of JAMS and
                        additional information about JAMS are available on the JAMS website (</span><span
                        class="c0 c18 c4"><a class="c3"
                                href="https://www.google.com/url?q=http://www.jamsadr.com&amp;sa=D&amp;source=editors&amp;ust=1616191592146000&amp;usg=AOvVaw1ZobRmSCq8i2yHLfuC4LW3">http://www.jamsadr.com</a></span><span
                        class="c14 c0 c4">). By agreeing to be bound by these Terms, you either (a) acknowledge and
                        agree that you
                        have read and understand the rules of JAMS or (b) waive your opportunity to read the rules of
                        JAMS and any
                        claim that the rules of JAMS are unfair or should not apply for any reason.</span></p>
        <p class="c9"><span class="c5 c4">Severability.</span><span class="c14 c0 c4">&nbsp;If any provision of
                        this
                        Section 11 is held invalid or unenforceable, it will be so held to the minimum extent required
                        by law and
                        all other provisions will remain valid and enforceable. Further, the waivers set forth in
                        Section <span class="c5 c4">&ldquo;No Class Arbitrations, Class Actions or Representative
                                Actions&ldquo;</span>
                        are severable from the other provisions of these Terms and will remain valid and enforceable
                        except
                        as prohibited by applicable law.
                </span></p>
        <p class="c9"><span class="c0 c4">If you involve us or our Suppliers in a dispute that is not the result of our
                        failure to fulfill our obligations, you will be obligated to reimburse our costs, including
                        attorneys&rsquo;
                        fees, unless the </span><span class="c0">Program Participant</span><span
                        class="c14 c0 c4">&nbsp;accepts
                        such responsibility.</span></p>
        <p class="c8"><span class="c14 c25 c4">12. Governing Law and Venue</span></p>
        <p class="c9"><span class="c0 c4">These Terms are governed by and construed in accordance with the laws of
                        the</span><span class="c0">&nbsp;State of Texas</span><span class="c0 c4">, except for its
                        conflict of
                        law rules. You expressly consent to the personal and exclusive jurisdiction and venue of the
                        state and
                        federal courts in </span><span class="c0">Texas</span><span class="c0 c1">&nbsp;</span><span
                        class="c14 c0 c4">for all purposes concerning arbitration or court actions pursuant to this
                        Agreement.</span></p>
        <p class="c8"><span class="c6">13. Miscellaneous</span></p>
        <p class="c9"><span class="c14 c0 c4">This Agreement, as updated from time to time, including all additional
                        terms
                        posted in the Program or Website is our complete agreement with respect to use of or access to
                        the Service
                        and supersedes all communications relating to the Service. These terms will not be construed
                        against BitBoss
                        nor its agents, vendors, or Suppliers for reason of its drafting or preparation and may be
                        modified or
                        waived only in writing as set forth above.</span></p>
        <p class="c9"><span class="c14 c0 c4">You may not assign or transfer any of your rights or delegate any of your
                        duties related to these terms. BitBoss may assign its obligations and delegate its duties under
                        this
                        Agreement at its complete discretion, and these terms will therefore bind lawful successors in
                        interest.</span></p>
        <p class="c8"><span class="c6">14. Indemnification</span></p>
        <p class="c9"><span class="c14 c0 c4">At your expense, you will defend and indemnify BitBoss and its agents and
                        their affiliates, employees, consultants, service providers, trustees, officers, and directors,
                        and any
                        other third parties BitBoss uses to provide the Service from and against any and all actual or
                        threatened
                        suits, actions, proceedings (at law or in equity), claims filed against them by any third party,
                        damages
                        suffered by them, costs, liabilities and expenses (including but not limited to, reasonable
                        attorneys&rsquo;
                        fees) arising out of, relating to, or resulting from (a) your use, inability to use, or misuse
                        of or access
                        to the Service, (b) from your actual or alleged breach of these Terms, (c) any actual or alleged
                        violation
                        of applicable laws or rules of any payment card association, network or company, and (d) your
                        violation of
                        the rights of any third party.</span></p>
        <p class="c8"><span class="c6">15. California Notice</span></p>
        <p class="c9"><span class="c14 c0 c4">Under California Civil Code Section 1789.3, California users are entitled
                        to
                        the following specific consumer rights notice: The Complaint Assistance Unit of the Division of
                        Consumer
                        Services of the California Department of Consumer Affairs may be contacted in writing at 1625
                        North Market
                        Blvd., Suite N 112, Sacramento, California 95834, or by telephone at (916) 445-1254 or toll free
                        (800)
                        952-5210.</span></p>
        <p class="c8"><span class="c6">16. Termination</span></p>
        <p class="c9"><span class="c0 c4">Notwithstanding any of these Terms, we reserve the right, without notice and
                        in
                        our sole discretion, to suspend or terminate your access to the Service, and to block or prevent
                        future
                        access to and use of the Service. Upon learning of termination, you must discontinue use of the
                        Service. We
                        will attempt to complete all </span><span class="c0">transfers</span><span
                        class="c14 c0 c4">&nbsp;that have
                        begun processing through the functionality at the time of termination that comply with these
                        Terms. We will
                        not be liable to you for the termination of the services or for any consequence arising out of
                        termination
                        of the Services.</span></p>
        <p class="c8"><span class="c6">17. Additional Terms for Electronic Payments</span></p>
        <p class="c8"><span class="c14 c19 c20">Payment Account Authorization</span></p>
        <p class="c9"><span class="c0">You will be issued BitBoss Digital US Dollars through the service electronically
                        by charging the account (bank account, credit card, prepaid
                        card</span><span class="c0"> or debit card) that you provide (the &ldquo;</span><span
                        class="c5 c4">Payment
                        Account</span><span class="c0 c4">&ldquo;). By initiating an Electronic Payment through the
                        Service, you
                        authorize us to initiate and complete the </span><span class="c0">transfer</span><span
                        class="c0 c4">&nbsp;on your behalf. You agree to maintain a balance or available credit limit in
                        the
                        specified Payment Account that is sufficient to fund all </span><span
                        class="c0">transfers</span><span class="c0 c4">&nbsp;that you initiate through the Service. You
                        certify that your Payment Account is in your
                        name and that you are authorized to use it.&nbsp; You represent and warrant that you have the
                        right to
                        authorize us, the </span><span class="c0">Program Participant</span><span class="c0 c4">s, and
                        payment
                        processors to charge the Payment Account for </span><span class="c0">transfer</span><span
                        class="c0 c4">s
                        that you initiate using the Service. You will indemnify and hold us harmless from any claims by
                        any other
                        owner of the Payment Account or any </span><span class="c0">Program Participant</span><span
                        class="c0 c4">&nbsp;or
                        payment processor resulting from your lack of authority, funds or credit. You may withdraw
                        authorization at
                        any time by contacting us at</span><span class="c0">&nbsp;</span><span
                        class="c0 c18">support@BitBoss.io</span><span class="c0">, </span><span class="c0 c4">or writing
                        us
                        at BitBoss,
                        Inc., </span><span class="c0">7435 South Eastern Ave, Suite 105-431, Las Vegas, NV
                        89123</span><span class="c0 c4">.</span><span class="c0 c1">&nbsp;</span><span class="c0 c4">You
                        acknowledge that once you
                        have initiated a </span><span class="c0">transfer</span><span class="c14 c0 c4">, we may not be
                        able to
                        cancel the electronic transfer if the transaction has already begun to be processed.&nbsp;
                </span></p>
        <p class="c9"><span class="c14 c0 c4">You are responsible for confirming the accuracy of the information you
                        provide
                        about each Electronic Payment you authorize and the amount of the transaction.&nbsp; Failure to
                        provide
                        accurate and complete information may prohibit the use of the Service or result in errors in
                        your requested
                        transfers to Program Participants. You authorize us to send communications to the Program
                        Participant on
                        your behalf in connection with the Service.</span></p>
        <p class="c8"><span class="c14 c19 c20">Our Liability</span></p>
        <p class="c9"><span class="c0 c4">If your </span><span class="c0">transfer</span><span
                        class="c14 c0 c4">&nbsp;is
                        not completed on time or in the correct amount according to these Terms, we will generally not
                        be liable for
                        your losses, damages or inconvenience.&nbsp; Please see Sections 9 and 10 above. We will also
                        not be liable,
                        for instance:</span></p>
        <ul class="c32 lst-kix_list_9-0 start">
                <li class="c30 li-bullet-0"><span class="c0 c4">If, through no fault of ours, you do not have enough
                                money in
                                your Account or Payment Account to make the </span><span class="c0">transfer</span><span
                                class="c14 c0 c4">.</span></li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If the </span><span class="c0">transfer</span><span
                                class="c0 c4">&nbsp;is rejected, returned, mishandled, or delayed by the </span><span
                                class="c0">Program
                                Participant</span><span class="c14 c0 c4">&nbsp;for any reason.</span></li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If you have provided us with incorrect information about
                                the
                        </span><span class="c0">Program Participant</span><span class="c14 c0 c4">.</span></li>
                <li class="c2 li-bullet-0">
                        <span class="c0 c4">If the Payment Account you specify as the payment source
                                is closed or does not contain sufficient funds to
                                complete the transfer or the charge is rejected or
                                returned by your bank or financial institution.
                        </span>
                </li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If the </span><span class="c0">transfer</span><span
                                class="c14 c0 c4">&nbsp;would go over the credit limit on your overdraft line of your
                                Payment Account.</span>
                </li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If the Service was not working properly and you knew
                                about the
                                problems when you started your </span><span class="c0">transfer</span><span
                                class="c14 c0 c4">.</span>
                </li>
                <li class="c2 li-bullet-0"><span class="c14 c0 c4">Due to fraud or attacks on our systems or the
                                Program.</span>
                </li>
                <li class="c2 li-bullet-0"><span class="c14 c0 c4">Due to third party service providers.</span></li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If your bank or financial institution, or the
                        </span><span class="c0">Program Participant</span><span class="c14 c0 c4">&rsquo;s bank or
                                financial institution,
                                takes an action that results in some or all of the funds not being timely
                                deposited.</span></li>
                <li class="c2 li-bullet-0"><span class="c0 c4">If circumstances beyond our control (such as fire or
                                flood)
                                prevent a </span><span class="c0">transfer</span><span class="c14 c0 c4">, despite
                                reasonable
                                precautions we have taken.</span></li>
                <li class="c34 c33 li-bullet-0"><span class="c14 c0 c4">There may be other exceptions stated in our
                                agreement
                                with you.</span></li>
        </ul>
        <p class="c8"><span class="c14 c19 c20">Refunds or Reversals of Completed Electronic Payments</span></p>
        <p class="c9"><span class="c0 c4">You cannot use the Service to request a refund, cancellation, correction, or
                        reversal of a completed Electronic Payment.&nbsp; In such situations, you will need to contact
                        your
                </span><span class="c0">Program Participant</span><span class="c0 c4">&rsquo;s customer service in order
                        to
                        request refund, cancellation, correction, or reversal to a completed </span><span
                        class="c0">transfer</span><span class="c0 c4">.&nbsp; If the </span><span class="c0">Program
                        Participant</span><span class="c0 c4">&nbsp;cannot, or is unwilling, to assist you with a
                        request for a
                        refund, cancellation, correction, or reversal of a completed </span><span
                        class="c0">transfer</span><span class="c14 c0 c4">, you should contact your financial
                        institution&rsquo;s customer service to determine if
                        your financial institution is able to provide additional assistance.</span></p>
        <p class="c8"><span class="c14 c19 c4">Disbursements</span></p>
        <p class="c9"><span class="c0 c4">A disbursement is a &ldquo;push&rdquo; of funds from a participating
                </span><span class="c0">Program Participant</span><span class="c0 c4">&nbsp;to your de</span><span
                        class="c0">bit card,
                </span><span class="c0">bank account</span><span class="c0">&nbsp;</span><span class="c0">or to another
                        Program Participant</span><span class="c0">. </span><span class="c0 c4">We reserve
                        the right to instruct our bank partner to hold any disbursement amount until the </span><span
                        class="c0">Program
                        Participant</span><span class="c0 c4">&nbsp;has successfully verified your payment
                        information.&nbsp;If the amount disbursed to
                        your Account is incorrect, delayed, or held back, please contact your </span><span
                        class="c0">Program Participant</span><span class="c14 c0 c4">&nbsp;for more information.</span>
        </p>
        <p class="c8"><span class="c6">18. Severability</span></p>
        <p class="c9"><span class="c14 c0 c4">If any provision of these Terms shall be deemed unlawful, void or for any
                        reason unenforceable, then that provision shall be deemed severable from these Terms and shall
                        not affect
                        the validity and enforceability of any remaining provisions.</span></p>
        <p class="c8"><span class="c16">19. Contact</span></p>
        <p class="c9"><span class="c0">All questions or comments about the Service, the Program, or these Terms should
                        be
                        directed to: &nbsp;
                </span>
        </p>

        <p class="c9">
                <span class="c14 c19 c4">Email:
                </span><span class="c0 c18">support@BitBoss.io</span>
        </p>
        <p class="c9">
                <span class="c14 c19 c4">Mail:</span><span class="c0">
                        BitBoss Corporation,, 7435 South Eastern Ave, Suite 105-431, Las Vegas, NV 89123
                </span>
        </p>
        <p class="c9">
                <span class="c14 c19 c4">Phone:</span><span class="c0">
                        720-432-9145
                </span>
        </p>

</div>

<footer>
        <div class="container team-container shadow-container">
                <app-footer></app-footer>
        </div>
</footer>