<section class="press">
    <ul class="press-list container blog-container">
        <li *ngFor="let post of press;" class="{{ getCardClassName(post) }}">
            <img [src]="post.img" alt="post-thumbnail">

            <div class="content">
                <p class="press-agenda">{{ post.agenda }}</p>

                <a [routerLink]="'post/' + post.id" class="press-link black">
                    <h3 class="text-primary black">{{ post.title }}</h3>
                </a>

                <p class="post-text text-primary sm black">{{ post.text }}</p>
                <p class="date text-primary xsm">{{ post.date }}</p>
            </div>
        </li>
    </ul>
</section>

<footer class="separate">
    <app-footer></app-footer>
</footer>