<div class="main-content">
    <div class="sub-content">
        <p class="title">Acceptable Use Policy</p>
        <p>Last Update: December 26, 2022</p>
        <p class="section">You are independently responsible for complying with all applicable laws in all of your
            actions
            related to your
            use of BitBoss's services, regardless of the purpose of the use. In addition, you must adhere to the terms
            of
            this Acceptable Use Policy. Violation of this Acceptable Use Policy constitutes a violation of the BitBoss
            User
            Agreement and may subject you to damages, including liquidated damages of $2,500.00 U.S. dollars per
            violation.
        </p>
        <p class="sub-title">Prohibited Activities</p>
        <p>You may not use the BitBoss service for activities that:</p>
        <div class="section list">
            <ol>
                <li>Violate any law, statute, ordinance or regulation.</li>
                <li>Relate to transactions involving:</li>
                <ol type="a" class="inner">
                    <li>narcotics, steroids, certain controlled substances or other products that present a risk to
                        consumer
                        safety</li>
                    <li>drug paraphernalia</li>
                    <li>cigarettes, tobacco/e-cigarettes or vaping products in any forms</li>
                    <li>items that encourage, promote, facilitate or instruct others to engage in illegal activity</li>
                    <li>stolen goods including digital and virtual goods</li>
                    <li>the promotion of hate, violence, racial or other forms of intolerance that is discriminatory or
                        the
                        financial exploitation of a crime</li>
                    <li>items that are considered obscene</li>
                    <li>items that infringe or violate any copyright, trademark, right of publicity or privacy or any
                        other
                        proprietary right under the laws of any jurisdiction</li>
                    <li>certain sexually oriented materials or services</li>
                    <li>ammunition, firearms, or certain firearm parts or accessories</li>
                    <li>certain weapons or knives regulated under applicable law</li>
                </ol>
                <li>Transactions that:</li>
                <ol type="a" class="inner">
                    <li>&nbsp;show the personal information of third parties in violation of applicable law</li>
                    <li>are associated with purchases of annuities or lottery contracts, lay-away systems, off-shore
                        banking
                        or
                        transactions to finance or refinance debts funded by a credit card</li>
                    <li>are for the sale of certain items before the seller has control or possession of the item</li>
                    <li>are by payment processors to collect payments on behalf of merchants</li>
                    <li>are associated with the sale of traveler's checks or money orders</li>
                    <li>involve currency exchanges or check cashing businesses</li>
                    <li>involve certain credit repair, debt settlement services, credit transactions or insurance
                        activities
                    </li>
                    <li>involve offering or receiving payments for the purpose of bribery or corruption</li>
                </ol>
                <li>Pyramid Schemes/Multiple Level Sales/Multi- Level Marketing: business model where investment returns
                    are
                    typically from principals of investments or membership fees instead from the underlying investment
                    gains.
                    Includes multi-level marketing goods, services, and business opportunities.</li>
                <li>Providing payment services which would fall under the definition of a money service business or an
                    electronic money institution. Services would also include the sale of stored value cards and escrow
                    services.</li>
                <li>Involve the sales of products or services identified by government agencies to have a high
                    likelihood of
                    being fraudulent.</li>
                <li>Any dating services that enable individuals to find and introduce themselves to new people with the
                    goal
                    of
                    developing a personal or romantic relationship.</li>
                <li>Relate to transactions involving any activity that requires pre-approval without having obtained
                    said
                    approval.</li>
            </ol>
        </div>
        <p class="sub-title">Activities Requiring Approval</p>
        <div>
            <p>BitBoss requires pre-approval to accept payments for certain services as detailed in the chart below:</p>
            <div class="section list">
                <table>
                    <tbody>
                        <tr>
                            <th>Category</th>
                            <th>Item or Service Requiring Pre-Approval&nbsp;(not intended to be
                                an
                                exhaustive list)</th>
                        </tr>
                        <tr>
                            <td>Transportation</td>
                            <td>Airlines and scheduled or non-scheduled charters/jets/air taxi operators.</td>
                        </tr>
                        <tr>
                            <td>Charities / Non-Profit</td>
                            <td>Collecting donations as a charity or non-profit organization.</td>
                        </tr>
                        <tr>
                            <td>High Value Items</td>
                            <td>Operating as a dealer in jewels, precious metals and stones.</td>
                        </tr>
                        <tr>
                            <td>Investments</td>
                            <td>Buying, selling, or brokering stocks, bonds, securities, options, figures, commodities,
                                contracts for difference/forex, mutual funds or an investment interest in any entity or
                                property.</td>
                        </tr>
                        <tr>
                            <td>Prescription Items</td>
                            <td>The sale of any product(s) requiring a prescription or prescription dispensingservices.
                            </td>
                        </tr>
                        <tr>
                            <td>Telemedicine Services</td>
                            <td>Providing medical services and consultations in a remote fashion.</td>
                        </tr>
                        <tr>
                            <td>Mature Audience Content</td>
                            <td>Any adult content delivered digitally including video on demand (VOD) and web-cam
                                activities.
                                BitBoss may be restricted from processing payments in certain jurisdictions for adult
                                DVD&rsquo;s, magazines and other adult themed products or services.</td>
                        </tr>
                        <tr>
                            <td>Live Streaming/Broadcasting</td>
                            <td>Any activities in which people transmit video, voice or text content in a live
                                environment
                                with
                                the potential of user interaction.</td>
                        </tr>
                        <tr>
                            <td>File-Sharing</td>
                            <td>Providing file sharing services including cyberlockers and similar remote digital file
                                sharing
                                services where uploaded content is accessible to the public or the service pays
                                uploaders
                                for
                                content.</td>
                        </tr>
                        <tr>
                            <td>Alcohol</td>
                            <td>Selling alcoholic beverages.</td>
                        </tr>
                        <tr>
                            <td>Medical Items or Services</td>
                            <td>All items classified as medical devices, and all services or treatment provided by a
                                person
                                or
                                organization holding itself out as a provider of health-care services, including, but
                                not
                                limited to, all health-care services for which government licensure is required in the
                                provider&rsquo;s jurisdiction or in the jurisdiction where services are being
                                provided.This
                                category includes &ldquo;Medical Tourism&rdquo; involving medical services to be
                                provided to
                                a
                                patient outside of that patient&rsquo;s home country.</td>
                        </tr>
                        <tr>
                            <td>Marketplaces</td>
                            <td>A&nbsp;marketplace&nbsp;is an e-commerce solution where third-party sellers can sell
                                their
                                products or services to customers.&nbsp;</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <p class="sub-title">Violations of the Acceptable Use Policy</p>
        <p>We encourage you to report violations of this Acceptable Use Policy to BitBoss immediately. If you have a
            question about whether a type of transaction may violate the Acceptable Use Policy, or wish to file a
            report,
            you can email to support@bitboss.io.&nbsp;</p>
    </div>
</div>
<footer>
    <div class="container team-container shadow-container">
        <app-footer></app-footer>
    </div>
</footer>